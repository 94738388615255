import { Truck, Headphones } from "lucide-react";
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const AppLauncher = () => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState<string | null>();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const hoverColors = [
    "#0070d2",
    "#0070d2",
    "#0070d2",
    "#f16121",
    "#f16121",
    "#ffc107",
    "#f16121",
    "#ffc107",
    "#ffc107",
  ];

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getTransitionDelay = (index: number) => {
    const row = Math.floor(index / 3);
    const col = index % 3;
    return (row + col) * 100;
  };

  const getSizeTransitionDelay = (index: number) => {
    const row = Math.floor(index / 3);
    const col = index % 3;
    return (row + col) * 100 + 200;
  };

  return (
    <div
      className="relative"
      role="navigation"
      aria-label="App"
      ref={dropdownRef}
    >
      <button
        aria-haspopup="dialog"
        className="p-2 flex items-center justify-center rounded-md"
        title="App Launcher"
        aria-expanded={isOpen}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="relative grid grid-cols-3 gap-[2px] w-[23px] h-[22px]">
          {hoverColors.map((color, index) => (
            <div
              key={index}
              className="w-[5px] h-[5px] rounded-full transform"
              style={{
                backgroundColor: "#747474",
                transition: "background-color 200ms ease, transform 200ms ease",
                transitionDelay: `${getTransitionDelay(index)}ms`,
                ...(isHovered && {
                  backgroundColor: color,
                  transform: "scale(1.2)",
                  transitionDelay: `${getTransitionDelay(
                    index
                  )}ms, ${getSizeTransitionDelay(index)}ms`,
                }),
              }}
            />
          ))}
        </div>
        <span className="sr-only">App Launcher</span>
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-1 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
          {[
            {
              title: "Nexus OMS",
              to: "/oms-dashboard",
              icon: <Truck className="w-4 h-4 text-[#000]" />,
            },
            {
              title: "Nexus CCM",
              to: "/ccm-dashboard",
              icon: <Headphones className="w-4 h-4 text-[#000]" />,
            },
          ].map((dropdownItem) => (
            <Link
              onClick={() => {
                setSelectedMenu(dropdownItem.title);
                setIsOpen(false);
              }}
              to={dropdownItem.to}
              key={dropdownItem.title}
              className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-blue-50"
            >
              {dropdownItem.icon}
              <span>{dropdownItem.title}</span>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default AppLauncher;
