import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ChevronDown,
  Home,
  ShoppingBag,
  Factory,
  DollarSign,
  BarChart2,
  Database,
  LogOut,
  User,
  Bell,
  Settings,
  Package,
  Users,
  LineChart,
  Network,
  Box,
  TrendingUp,
  Wallet,
  FileCheck,
  Calendar,
  HardDrive,
  Truck,
  CheckCircle,
  Menu,
  X,
  Codesandbox,
} from "lucide-react";
import AppLauncher from "../appLauncherButton";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/slices/authSlice";

const Navbar: React.FC = () => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openMobileDropdowns, setOpenMobileDropdowns] = useState<string[]>([]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  interface NavItem {
    title: string;
    link: string;
    icon: React.ReactNode;
    dropdownItems?: { title: string; link: string; icon?: React.ReactNode }[];
    onClick?: any;
  }
  const handleLogout = () => {
    localStorage.clear(); // Or be more specific: localStorage.removeItem('specific_key')

    dispatch(logout());

    navigate("/login");
  };
  const navItems: NavItem[] = [
    {
      title: "Dashboard",
      link: "/oms-dashboard",
      icon: <Home className="w-4 h-4 text-[#000]" />,
    },
    {
      title: "EU Orders",
      link: "/eu-orders",
      icon: <ShoppingBag className="w-4 h-4 text-[#000]" />,
    },
    {
      title: "Production Line",
      link: "#",
      icon: <Factory className="w-4 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Planning Phase",
          link: "#",
          icon: <Calendar className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Production Phase",
          link: "#",
          icon: <HardDrive className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Shipment Phase",
          link: "#",
          icon: <Truck className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Quality Control Phase",
          link: "#",
          icon: <CheckCircle className="w-4 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Financial",
      link: "#",
      icon: <DollarSign className="w-4 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Salesview",
          link: "#",
          icon: <TrendingUp className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Bank Reconciliation",
          link: "#",
          icon: <Wallet className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Order Release",
          link: "#",
          icon: <FileCheck className="w-4 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "OMS Reporting",
      link: "#",
      icon: <BarChart2 className="w-4 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "Demand",
          link: "/demand",
          icon: <TrendingUp className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Sales",
          link: "/sales",
          icon: <DollarSign className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Supply",
          link: "/supply",
          icon: <Box className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Performance",
          link: "/performance",
          icon: <LineChart className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "Leadership",
          link: "#",
          icon: <Network className="w-4 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Master Data Setup",
      link: "#",
      icon: <Database className="w-4 h-4 text-[#000]" />,
      dropdownItems: [
        {
          title: "EU Products",
          link: "#",
          icon: <Package className="w-4 h-4 text-[#000]" />,
        },
        {
          title: "EU Customers",
          link: "#",
          icon: <Users className="w-4 h-4 text-[#000]" />,
        },
      ],
    },
    {
      title: "Logout",
      link: "/login",
      icon: <LogOut className="w-4 h-4 text-[#000]" />,
      onClick: handleLogout,
    },
  ];

  const toggleMobileDropdown = (title: string) => {
    setOpenMobileDropdowns((prev) =>
      prev.includes(title)
        ? prev.filter((item) => item !== title)
        : [...prev, title]
    );
  };

  const handleNavItemClick = (item: NavItem) => {
    if (item.dropdownItems) {
      setActiveDropdown((prev) => (prev === item.title ? null : item.title));
    } else {
      navigate(item.link);
      setActiveDropdown(null);
    }
    if (item.title === "Logout") {
      item.onClick();
    }
  };

  const MobileNavItem = ({ item }: { item: NavItem }) => {
    const isOpen = openMobileDropdowns.includes(item.title);

    return (
      <div className="border-b border-gray-200">
        {item.dropdownItems ? (
          <div>
            <button
              onClick={(e) => {
                e.stopPropagation();
                toggleMobileDropdown(item.title);
              }}
              className="w-full flex items-center justify-between px-4 py-3 text-sm text-gray-700 hover:bg-blue-50"
            >
              <div className="flex items-center space-x-2">
                {item.icon}
                <span>{item.title}</span>
              </div>
              <ChevronDown
                className={`w-4 h-4 transition-transform duration-200 ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            </button>
            <div
              className={`
                overflow-hidden transition-all duration-200 ease-in-out bg-gray-50
                ${isOpen ? "max-h-[500px]" : "max-h-0"}
              `}
            >
              {item.dropdownItems.map((subItem) => (
                <Link
                  key={subItem.title}
                  to={subItem.link}
                  className="flex items-center space-x-2 px-6 py-3 text-sm text-gray-600 hover:bg-blue-50"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  {subItem.icon}
                  <span>{subItem.title}</span>
                </Link>
              ))}
            </div>
          </div>
        ) : (
          <Link
            to={item.link}
            className="flex items-center space-x-2 px-4 py-3 text-sm text-gray-700 hover:bg-blue-50"
            onClick={() => setIsDrawerOpen(false)}
          >
            {item.icon}
            <span>{item.title}</span>
          </Link>
        )}
      </div>
    );
  };

  return (
    <>
      <nav
        className="bg-white border-b fixed w-full top-0 z-50"
        style={{ borderBottomWidth: "3px", borderBottomColor: "#1b96ff" }}
      >
        <div className="px-4">
          <div className="flex items-center justify-between h-lg-10 h-md-16">
            <div className="flex items-center space-x-4">
              <AppLauncher />
              <span className="text-xl font-bold">Nexus OMS</span>
              {/* Desktop Navigation */}
              <div className="hidden md:flex space-x-1 ml-6" ref={dropdownRef}>
                {navItems.map((item, index) => (
                  <div key={item.title} className="relative">
                    <div
                      onClick={() => handleNavItemClick(item)}
                      className={`
                        cursor-pointer flex items-center space-x-2 px-3 py-2 h-full text-sm font-medium text-[#000] hover:bg-blue-50
                        ${index === 0 ? "border-r border-gray-200" : ""} 
                        ${
                          item.title === "Logout"
                            ? "border-l border-gray-200"
                            : ""
                        }
                      `}
                    >
                      {item.icon}
                      <span>{item.title}</span>
                      {item.dropdownItems && (
                        <ChevronDown
                          className={`ml-1 h-4 w-4 text-[#000] transition-transform duration-200 ${
                            activeDropdown === item.title ? "rotate-180" : ""
                          }`}
                        />
                      )}
                    </div>

                    {item.dropdownItems && activeDropdown === item.title && (
                      <div className="absolute left-0 mt-1 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-50">
                        {item.dropdownItems.map((dropdownItem) => (
                          <Link
                            key={dropdownItem.title}
                            to={dropdownItem.link}
                            className="flex items-center space-x-2 px-4 py-2 text-sm text-gray-700 hover:bg-blue-50"
                            onClick={() => setActiveDropdown(null)}
                          >
                            {dropdownItem.icon}
                            <span>{dropdownItem.title}</span>
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Desktop Action Buttons */}
            <div className="hidden md:flex items-center space-x-4">
              <button className="p-2 rounded-md hover:bg-blue-50">
                <User className="h-5 w-5 text-[#000]" />
              </button>
              <button className="p-2 rounded-md hover:bg-blue-50">
                <Settings className="h-5 w-5 text-[#000]" />
              </button>
              <button className="p-2 rounded-md hover:bg-blue-50">
                <Bell className="h-5 w-5 text-[#000]" />
              </button>
            </div>

            {/* Mobile Menu Button */}
            <button
              className="md:hidden p-2 rounded-md hover:bg-blue-50"
              onClick={(e) => {
                e.stopPropagation();
                setIsDrawerOpen(true);
              }}
            >
              <Menu className="h-6 w-6 text-[#000]" />
            </button>
          </div>
        </div>
      </nav>

      {/* Add margin to account for fixed navbar */}
      <div className="xl:h-10 lg:h-16 md:h-20 sm:h-10 h-10" />

      {/* Mobile Drawer Backdrop */}
      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 transition-opacity duration-300 md:hidden z-40"
          onClick={() => setIsDrawerOpen(false)}
        />
      )}

      {/* Mobile Drawer */}
      <div
        className={`
          fixed top-0 right-0 bottom-0 w-3/4 bg-white shadow-xl transform transition-transform duration-300 ease-in-out md:hidden z-50
          ${isDrawerOpen ? "translate-x-0" : "translate-x-full"}
        `}
      >
        <div className="p-4 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-lg font-semibold">Menu</h2>
          <button
            onClick={() => setIsDrawerOpen(false)}
            className="p-2 rounded-md hover:bg-blue-50"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="flex flex-col h-[calc(100%-60px)]">
          <div className="flex-grow overflow-y-auto">
            <div className="py-2">
              {navItems.map((item) => (
                <MobileNavItem key={item.title} item={item} />
              ))}
            </div>
          </div>

          <div className="flex items-center justify-around p-4 border-t border-gray-200 bg-white">
            <button className="p-2 rounded-md hover:bg-blue-50">
              <User className="h-5 w-5 text-[#000]" />
            </button>
            <button className="p-2 rounded-md hover:bg-blue-50">
              <Settings className="h-5 w-5 text-[#000]" />
            </button>
            <button className="p-2 rounded-md hover:bg-blue-50">
              <Bell className="h-5 w-5 text-[#000]" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
