import React, { useState, useEffect } from "react";
import Navbar from "../../components/navigation/navbar";
import {
  ArrowUpDown,
  BanknoteIcon,
  CalendarIcon,
  PackageIcon,
  TruckIcon,
  UserPlusIcon,
  XCircleIcon,
} from "lucide-react";
import moment from "moment";
import { AnalyticCard } from "../../components/analyticsCard/analyticsCard";
import DemandNavigation from "./components/tabBar";
import Filters from "../../components/filters";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { GET, POST } from "../../utils/apiCalls";
import { setFilters } from "../../redux/slices/filterSlice";
import API from "../../config/api";

const Demand = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { filters, lastFetched } = useSelector((state: any) => state.Filter);
  const dispatch = useDispatch();
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});
  const [headingTxt, setHeadingTxt] = useState("Demand by Date");
  const [isSalesChannel2Disabled, setIsSalesChannel2Disabled] = useState(true);
  const [form] = Form.useForm();
  useEffect(() => {
    getFilters();
  }, [1]);
  const getFilters = async () => {
    try {
      if (filters.length > 0) {
        return;
      }
      const response: any = await GET(API.FILTERS);

      if (response.length) {
        dispatch(setFilters(response));
      }

      const initialValues: any = {
        year: [],
        periods: [],
        weeks: [],
        country: [],
        channel: [],
        salesChannel: [],
      };
      form.setFieldsValue(initialValues);
    } catch (error) {
      console.log(error);
    }
  };
  const metrics = [
    {
      icon: <PackageIcon className="w-5 h-5 text-amber-500" />,
      value: "3.0%",
      label: "Demand NSV vs LY (%)",
      headerIcon: <PackageIcon className="w-4 h-4 text-amber-500" />,
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
      value: "0.2%",
      label: "Demand Orders vs LY (%)",
      headerIcon: <BanknoteIcon className="w-4 h-4 text-blue-600" />,
    },
    {
      icon: <TruckIcon className="w-5 h-5 text-green-600" />,
      value: "141.01K",
      label: "Number Of orders",
      headerIcon: <TruckIcon className="w-4 h-4 text-green-600" />,
    },
    {
      icon: <XCircleIcon className="w-5 h-5 text-red-500" />,
      value: "13.51M",
      label: "Demand NSV",
      headerIcon: <XCircleIcon className="w-4 h-4 text-red-500" />,
    },
    {
      icon: <CalendarIcon className="w-5 h-5 text-purple-500" />,
      value: "5.2",
      label: "AOV vs LY",
      headerIcon: <CalendarIcon className="w-4 h-4 text-purple-500" />,
    },
    {
      icon: <UserPlusIcon className="w-5 h-5 text-blue-500" />,
      value: "5",
      label: "New Customers during this Period",
      headerIcon: <UserPlusIcon className="w-4 h-4 text-blue-500" />,
    },
  ];

  const columns = [
    { key: "MARS_YEAR", label: "Mars Year" },
    { key: "MARS_PERIOD", label: "Mars Period" },
    { key: "ORDERED_DATE", label: "Ordered Date" },
    { key: "DEMAND_NSV", label: "Demand NSV € (OMS)" },
    { key: "DEMAND_NSV_LY", label: "Demand NSV € LY (OMS)" },
    { key: "DEMAND_NSV_VS_LY", label: "Demand NSV vs. LY (%)" },
    { key: "NO_OF_ORDERS", label: "No of Orders (OMS)" },
    { key: "NO_OF_ORDERS_LY", label: "No of Orders (OMS) LY" },
    { key: "NO_OF_ORDERS_VS_LY", label: "No of Orders vs. LY (%)" },
    { key: "DEMAND_AOV", label: "Demand AOV € (OMS)" },
    { key: "DEMAND_AOV_LY", label: "Demand AOV € (OMS) LY" },
    { key: "DEMAND_AOV_VS_LY", label: "Demand AOV vs LY" },
    { key: "QUOTES_LEADS", label: "QUOTES/LEADS (OMS)" },
    { key: "NSV_QUOTES_LEADS", label: "NSV QUOTES/LEADS (OMS)" },
  ];
  const dummyData = [
    {
      MARS_YEAR: 2024,
      MARS_PERIOD: 1,
      ORDERED_DATE: "2023-12-31",
      DEMAND_NSV: 5647,
      DEMAND_NSV_LY: 5741,
      DEMAND_NSV_VS_LY: -1.6,
      NO_OF_ORDERS: 82,
      NO_OF_ORDERS_LY: 79,
      NO_OF_ORDERS_VS_LY: 3.8,
      DEMAND_AOV: 69,
      DEMAND_AOV_LY: 59,
      DEMAND_AOV_VS_LY: 30,
      QUOTES_LEADS: 45,
      NSV_QUOTES_LEADS: 639,
    },
    {
      MARS_YEAR: 2024,
      MARS_PERIOD: 1,
      ORDERED_DATE: "2024-01-01",
      DEMAND_NSV: 10311,
      DEMAND_NSV_LY: 14409,
      DEMAND_NSV_VS_LY: -28.4,
      NO_OF_ORDERS: 120,
      NO_OF_ORDERS_LY: 171,
      NO_OF_ORDERS_VS_LY: -29.8,
      DEMAND_AOV: 86,
      DEMAND_AOV_LY: 59,
      DEMAND_AOV_VS_LY: 30,
      QUOTES_LEADS: 45,
      NSV_QUOTES_LEADS: 639,
    },
    {
      MARS_YEAR: 2024,
      MARS_PERIOD: 1,
      ORDERED_DATE: "2024-01-02",
      DEMAND_NSV: 15197,
      DEMAND_NSV_LY: 19345,
      DEMAND_NSV_VS_LY: -21.4,
      NO_OF_ORDERS: 174,
      NO_OF_ORDERS_LY: 210,
      NO_OF_ORDERS_VS_LY: -17.1,
      DEMAND_AOV: 87,
      DEMAND_AOV_LY: 59,
      DEMAND_AOV_VS_LY: 30,
      QUOTES_LEADS: 45,
      NSV_QUOTES_LEADS: 639,
    },
    {
      MARS_YEAR: 2024,
      MARS_PERIOD: 1,
      ORDERED_DATE: "2024-01-03",
      DEMAND_NSV: 24170,
      DEMAND_NSV_LY: 22960,
      DEMAND_NSV_VS_LY: 5.3,
      NO_OF_ORDERS: 199,
      NO_OF_ORDERS_LY: 216,
      NO_OF_ORDERS_VS_LY: -7.9,
      DEMAND_AOV: 121,
      DEMAND_AOV_LY: 59,
      DEMAND_AOV_VS_LY: 30,
      QUOTES_LEADS: 45,
      NSV_QUOTES_LEADS: 639,
    },
    {
      MARS_YEAR: 2024,
      MARS_PERIOD: 1,
      ORDERED_DATE: "2024-01-04",
      DEMAND_NSV: 26170,
      DEMAND_NSV_LY: 20690,
      DEMAND_NSV_VS_LY: 26.2,
      NO_OF_ORDERS: 189,
      NO_OF_ORDERS_LY: 196,
      NO_OF_ORDERS_VS_LY: -3.6,
      DEMAND_AOV: 138,
      DEMAND_AOV_LY: 59,
      DEMAND_AOV_VS_LY: 30,
      QUOTES_LEADS: 45,
      NSV_QUOTES_LEADS: 639,
    },
  ];
  const handleFormChange = async (changedValues: any, allValues: any) => {
    try {
      if (changedValues.salesChannel !== undefined) {
        if (
          changedValues.salesChannel &&
          changedValues.salesChannel.length > 0
        ) {
          const response = await POST(API.FILTERSBYCHANNEL, {
            channel: changedValues.salesChannel,
          });
          setSalesChannel2Options(response);
          setIsSalesChannel2Disabled(false);
        } else {
          form.setFieldsValue({
            salesChannel: [],
            salesChannel2: [],
          });
          setSalesChannel2Options({});
          setIsSalesChannel2Disabled(true);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const getPercentageColor = (value: any) => {
    const numValue = parseFloat(value);
    if (numValue > 0) return "bg-green-100 text-green-800";
    if (numValue < 0) return "bg-red-100 text-red-800";
    return "text-gray-600";
  };

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercentage = (value: any) => {
    return `${value.toFixed(1)}%`;
  };
  const totalRow = {
    MARS_YEAR: "Total",
    DEMAND_NSV: 13509100,
    DEMAND_NSV_LY: 13111972,
    DEMAND_NSV_VS_LY: 3.0,
    NO_OF_ORDERS: 141013,
    NO_OF_ORDERS_LY: 140781,
    NO_OF_ORDERS_VS_LY: 0.2,
    DEMAND_AOV: 96,
    DEMAND_AOV_LY: 200,
    DEMAND_AOV_VS_LY: 320,
    QUOTES_LEADS: 451,
    NSV_QUOTES_LEADS: 639,
  };
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <Navbar />
      <div className="px-6 pt-4">
        <div className="flex items-center bg-blue-50 px-4 pt-4">
          <Filters
            filters={filters}
            form={form}
            handleFormChange={handleFormChange}
            isSalesChannel2Disabled={isSalesChannel2Disabled}
            salesChannel2Options={salesChannel2Options}
          />
        </div>
      </div>
      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <DemandNavigation selectedTabTxt={(val: any) => setHeadingTxt(val)} />
      </div>
      <div className="bg-blue-50 p-4 m-6 mt-2 text-sm text-gray-600 italic">
        <p>
          Information: All orders have been selected,{" "}
          <span className="text-red-500">
            excluding those marked as "Lead" by Salesforce
          </span>
          . The number of orders and ordered NSV{" "}
          <span className="text-red-500">
            may still change for a previous date if an order returns to "Lead"
            status for modification
          </span>
          . This could temporarily alter the count of orders and also alter the
          NSV if the client decides to add or remove products.
        </p>
      </div>
      <div className="p-4 md:p-6">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
          {/* Left Section */}
          <div className="sm:col-span-12 md:col-span-3 lg:col-span-2 space-y-4">
            {metrics.map((metric, index) => (
              <AnalyticCard
                key={index}
                icon={metric.icon}
                value={metric.value}
                label={metric.label}
              />
            ))}
          </div>
          <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4">
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold text-gray-700">
                  {headingTxt}
                </h2>
              </div>
              <div className="overflow-x-auto">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        {columns.map(({ key, label }) => (
                          <th
                            key={key}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
                          >
                            <div className="flex items-center space-x-1">
                              <span>{label}</span>
                              <ArrowUpDown className="h-4 w-4" />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {dummyData.map((row, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.MARS_YEAR}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            P{row.MARS_PERIOD}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {moment(row.ORDERED_DATE).format("DD MMMM YYYY")}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {formatCurrency(row.DEMAND_NSV)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {formatCurrency(row.DEMAND_NSV_LY)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row.DEMAND_NSV_VS_LY
                              )}`}
                            >
                              {formatPercentage(row.DEMAND_NSV_VS_LY)}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.NO_OF_ORDERS}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.NO_OF_ORDERS_LY}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row.NO_OF_ORDERS_VS_LY
                              )}`}
                            >
                              {formatPercentage(row.NO_OF_ORDERS_VS_LY)}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {formatCurrency(row.DEMAND_AOV)}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.DEMAND_AOV_LY}
                          </td>{" "}
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.DEMAND_AOV_VS_LY}
                          </td>{" "}
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.QUOTES_LEADS}
                          </td>{" "}
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.NSV_QUOTES_LEADS}
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-blue-600">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          {totalRow.MARS_YEAR}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-white"></td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow.DEMAND_NSV)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow.DEMAND_NSV_LY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-3 py-1 text-sm font-medium text-white">
                            {formatPercentage(totalRow.DEMAND_NSV_VS_LY)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {totalRow.NO_OF_ORDERS.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {totalRow.NO_OF_ORDERS_LY.toLocaleString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-3 py-1 text-sm font-medium text-white">
                            {formatPercentage(totalRow.NO_OF_ORDERS_VS_LY)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow.DEMAND_AOV)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow.DEMAND_AOV_LY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow.DEMAND_AOV_VS_LY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow.QUOTES_LEADS)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow.NSV_QUOTES_LEADS)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Demand;
