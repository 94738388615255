import React, { useState } from "react";
import Navbar from "../../components/navigation/navbar";
import DemandNavigation from "./components/tabBar";
import { ArrowUpDown } from "lucide-react";
import moment from "moment";

const Sales = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dailyData = [
    {
      marsYear: "2024",
      period: "P11",
      date: "01 November 2024",
      country: "AT",
      channel: "B2C",
      ordersInvoiced: 50,
      productsFlavourNSV: 168935,
      totalTaxFree: 2360,
      taxAmount: 472,
      totalTaxIncluded: 2832,
      fees: 150,
      totalProductsShipTaxIncluded: 2982,
      totalProductsShipTaxFree: 2510,
      shipAmountTaxFree: 150,
      totalTaxFreeLY: 2100,
      taxAmountLY: 420,
      totalTaxIncludedLY: 2520,
      feesLY: 140,
      totalProductsShipTaxIncludedLY: 2660,
      totalProductsShipTaxFreeLY: 2240,
      totalProductsShipTaxFacesLY: 2240,
      shipAmountTaxFreeLY: 140,
    },
    {
      marsYear: "2024",
      period: "P11",
      date: "01 November 2024",
      country: "BE",
      channel: "B2B",
      ordersInvoiced: 45,
      productsFlavourNSV: 193053,
      totalTaxFree: 5700,
      taxAmount: 1140,
      totalTaxIncluded: 6840,
      fees: 200,
      totalProductsShipTaxIncluded: 7040,
      totalProductsShipTaxFree: 5900,
      shipAmountTaxFree: 200,
      totalTaxFreeLY: 5200,
      taxAmountLY: 1040,
      totalTaxIncludedLY: 6240,
      feesLY: 180,
      totalProductsShipTaxIncludedLY: 6420,
      totalProductsShipTaxFreeLY: 5380,
      totalProductsShipTaxFacesLY: 5380,
      shipAmountTaxFreeLY: 180,
    },
  ];

  const periodData = [
    {
      marsYearPeriod: "2024 P11",
      invoicedNSV: 734634,
      invoicedProductsFlavourNSV: 734706,
      invoicedNSVLY: 853971,
      productsFlavourNSVLY: 853975,
    },
  ];

  const weeklyCountryData = [
    {
      marsYear: "2024",
      period: "P11",
      country: "AT",
      marsWeek: "W1",
      channel: "B2B",
      ordersInvoiced: 4,
      productsFlavourNSV: 168935,
      totalTaxFree: 2360,
      taxAmount: 472,
      totalTaxIncluded: 2832,
      fees: 150,
      totalProductsShipTaxIncluded: 2982,
      totalProductsShipTaxFreeNotFaces: 2510,
      totalProductsShipTaxFreeFaces: 2510,
      shipAmountTaxFree: 150,
      totalTaxFreeLY: 2100,
      taxAmountLY: 420,
      totalTaxIncludedLY: 2520,
      feesLY: 140,
      totalProductsShipTaxIncludedLY: 2660,
      totalProductsShipTaxFreeNotFacesLY: 2240,
      totalProductsShipTaxFreeFacesLY: 2240,
      shipAmountTaxFreeLY: 140,
    },
    {
      marsYear: "2024",
      period: "P11",
      country: "AT",
      marsWeek: "W2",
      channel: "B2B",
      ordersInvoiced: 8,
      productsFlavourNSV: 193053,
      totalTaxFree: 1080,
      taxAmount: 216,
      totalTaxIncluded: 1296,
      fees: 120,
      totalProductsShipTaxIncluded: 1416,
      totalProductsShipTaxFreeNotFaces: 1200,
      totalProductsShipTaxFreeFaces: 1200,
      shipAmountTaxFree: 120,
      totalTaxFreeLY: 980,
      taxAmountLY: 196,
      totalTaxIncludedLY: 1176,
      feesLY: 110,
      totalProductsShipTaxIncludedLY: 1286,
      totalProductsShipTaxFreeNotFacesLY: 1090,
      totalProductsShipTaxFreeFacesLY: 1090,
      shipAmountTaxFreeLY: 110,
    },
  ];
  const weeklyData = [
    {
      marsYearPeriod: "2024 P11",
      marsWeek: "W1",
      productsFlavourNSV: 168935,
      NSV: 168876,
      productsFlavourNSVLY: 219221,
      NSVLY: 9221,
    },
    {
      marsYearPeriod: "2024 P11",
      marsWeek: "W2",
      productsFlavourNSV: 193053,
      NSV: 193050,
      productsFlavourNSVLY: 235251,
      NSVLY: 219221,
    },
  ];

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };
  const TableHeader = ({ title }: any) => (
    <div className="p-4 border-b border-gray-200">
      <h2 className="text-lg font-semibold text-gray-700">{title}</h2>
    </div>
  );
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <Navbar />
      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <DemandNavigation />
      </div>
      <div className="p-4 md:p-6">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          {/* Daily Sales View */}
          <div className="sm:col-span-12 md:col-span-7">
            <div className="bg-white rounded-lg shadow">
              <TableHeader title="Daily Sales View (Incl. Financial columns)" />
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Mars Year/Period <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Date <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Country <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Channel <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Number of Orders Invoiced{" "}
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Products Flavour NSV{" "}
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total (tax free) <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Tax Amount <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total(Taxes Included)
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Fees <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total Products + ship (Taxes Included){" "}
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total Products + ship (Free Taxes/Not faces){" "}
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Ship Amount (Free Taxes)
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total (tax tree) LY
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Tax Amount LY <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total (tax included) LY
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Fees LY
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total Products + ship (Taxes Included) LY
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total Products + ship (Frre Taxes / Not Faces) LY
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Total Products + ship (Frre Taxes / Faces) LY
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="whitespace-nowrap px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Ship Amount (Frre Taxes) LY
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {dailyData.map((row, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 border-t border-gray-200"
                      >
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.marsYear} {row.period}
                        </td>
                        <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                          {row.date}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.country}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.channel}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.ordersInvoiced}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.productsFlavourNSV)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalTaxFree)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.taxAmount)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalTaxIncluded)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.fees)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxIncluded)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxFree)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.shipAmountTaxFree)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalTaxFreeLY)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.taxAmountLY)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalTaxIncludedLY)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.feesLY)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxIncludedLY)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxFreeLY)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxFacesLY)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.shipAmountTaxFreeLY)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td
                        colSpan={4}
                        className="px-4 py-3 text-sm font-bold text-white"
                      >
                        Total
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {dailyData.reduce(
                          (sum, row) => sum + row.ordersInvoiced,
                          0
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.productsFlavourNSV,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.totalTaxFree,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce((sum, row) => sum + row.taxAmount, 0)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.totalTaxIncluded,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce((sum, row) => sum + row.fees, 0)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) =>
                              sum + row.totalProductsShipTaxIncluded,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.totalProductsShipTaxFree,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.shipAmountTaxFree,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.totalTaxFreeLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.taxAmountLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.totalTaxIncludedLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce((sum, row) => sum + row.feesLY, 0)
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) =>
                              sum + row.totalProductsShipTaxIncludedLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.totalProductsShipTaxFreeLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.totalProductsShipTaxFacesLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(
                          dailyData.reduce(
                            (sum, row) => sum + row.shipAmountTaxFreeLY,
                            0
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Period View */}
          <div className="sm:col-span-12 md:col-span-5">
            <div className="bg-white rounded-lg shadow">
              <TableHeader title="Period View" />
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Mars Year/Period <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Invoiced NSV <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Invoiced Products Flavour NSV{" "}
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Invoiced NSV LY <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center gap-1">
                          Products Flavour NSV LY{" "}
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {periodData.map((row, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 border-t border-gray-200"
                      >
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {row.marsYearPeriod}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.invoicedNSV)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.invoicedProductsFlavourNSV)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.invoicedNSVLY)}
                        </td>
                        <td className="px-4 py-3 text-sm text-gray-600">
                          {formatCurrency(row.productsFlavourNSVLY)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td className="px-4 py-3 text-sm font-bold text-white">
                        Total
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(734634)}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(734706)}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(853971)}
                      </td>
                      <td className="px-4 py-3 text-sm text-white">
                        {formatCurrency(853975)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-7 lg:col-span-7 space-y-4">
            <div className="bg-white rounded-lg shadow">
              <TableHeader title="Weekly Sales View (by Country)" />
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr className="whitespace-nowrap">
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Mars Year/Period</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Country</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Mars Week</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Channel</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Number of Orders Invoiced</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Products Flavour NSV</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Total (tax free)</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Tax Amount</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Total (taxes included)</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Fees</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Total products + ship(tax included)</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>
                            Total products + ship(free taxes/not faces)
                          </span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Total products + ship(free taxes/ faces)</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Ship Amount(free taxes)</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Total(taxes free) LY</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Tax Amount LY</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Total(Taxes Included) LY</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Fees LY</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Total products + ship(taxes included) LY</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>
                            Total products + ship(free taxes /not faces) LY
                          </span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>
                            Total products + ship(free taxes / faces) LY
                          </span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Ship Amount(free taxes) LY</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {weeklyCountryData.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.marsYear} {row.period}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.country}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.marsWeek}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.channel}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.ordersInvoiced}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.productsFlavourNSV)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalTaxFree)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.taxAmount)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalTaxIncluded)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.fees)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxIncluded)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxFreeNotFaces)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxFreeFaces)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.shipAmountTaxFree)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalTaxFreeLY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.taxAmountLY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalTaxIncludedLY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.feesLY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxIncludedLY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(
                            row.totalProductsShipTaxFreeNotFacesLY
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.totalProductsShipTaxFreeFacesLY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.shipAmountTaxFreeLY)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td
                        colSpan={4}
                        className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white"
                      >
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {weeklyCountryData.reduce(
                          (sum, row) => sum + row.ordersInvoiced,
                          0
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.productsFlavourNSV,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.totalTaxFree,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.taxAmount,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.totalTaxIncluded,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.fees,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) =>
                              sum + row.totalProductsShipTaxIncluded,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) =>
                              sum + row.totalProductsShipTaxFreeNotFaces,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) =>
                              sum + row.totalProductsShipTaxFreeFaces,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.shipAmountTaxFree,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.totalTaxFreeLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.taxAmountLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.totalTaxIncludedLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.feesLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) =>
                              sum + row.totalProductsShipTaxIncludedLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) =>
                              sum + row.totalProductsShipTaxFreeNotFacesLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) =>
                              sum + row.totalProductsShipTaxFreeFacesLY,
                            0
                          )
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(
                          weeklyCountryData.reduce(
                            (sum, row) => sum + row.shipAmountTaxFreeLY,
                            0
                          )
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Weekly View */}
          <div className="sm:col-span-12 md:col-span-5 lg:col-span-5 space-y-4">
            <div className="bg-white rounded-lg shadow">
              <TableHeader title="Weekly View" />
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Mars Year/Period</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Mars Week</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Products Flavour NSV</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>NSV</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>Products Flavour NSV LY</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        <div className="flex items-center space-x-1">
                          <span>NSV LY</span>
                          <ArrowUpDown className="h-4 w-4" />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {weeklyData.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.marsYearPeriod}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {row.marsWeek}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.productsFlavourNSV)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.NSV)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.productsFlavourNSVLY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                          {formatCurrency(row.NSVLY)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600">
                      <td
                        colSpan={2}
                        className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white"
                      >
                        Total
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(734706)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(734634)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(853975)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-white">
                        {formatCurrency(33975)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sales;
