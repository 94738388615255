import React, { useState } from "react";
import { Select } from "antd";

const DemandNavigation = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedRoundTab, setSelectedRoundTab] = useState(1);
  const [headingTxt, setHeadingTxt] = useState(false);

  const tabCard1 = [
    { id: 1, title: "Carrier" },
    { id: 2, title: "Factory Consumption" },
    { id: 3, title: "Wheather Forcats" },
    { id: 4, title: "Forcast Shipping" },
    { id: 5, title: "Freight Shipment" },
  ];
  const tabCard2 = [
    { id: 1, title: "Carrier" },
    { id: 2, title: "Carrier Tracking" },
  ];

  const options = [
    { label: "Free", value: "free" },
    { label: "50%", value: "50%" },
    { label: "10%", value: "10%" },
  ];

  const handleTabClick = (id: any) => {
    setSelectedTab(id);
  };

  const handleRoundTabClick = (id: any) => {
    setSelectedRoundTab(id);
  };

  return (
    <div className="w-full overflow-x-scroll">
      <div className="flex gap-3 items-center justify-between">
        {tabCard1.map((item) => (
          <button
            key={item.id}
            onClick={() => handleRoundTabClick(item.id)}
            className={`w-full whitespace-nowrap px-6 py-2 rounded-xl text-sm font-medium transition-colors duration-200 ${
              selectedRoundTab === item.id
                ? "bg-blue-400 text-white"
                : "border border-gray-300 text-gray-600 hover:bg-gray-100"
            }`}
          >
            {item.title}
          </button>
        ))}
      </div>

      <div className="flex gap-2 mt-3">
        {tabCard2.map((item) => (
          <button
            key={item.id}
            onClick={() => handleTabClick(item.id)}
            className={`w-full whitespace-nowrap px-8 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
              selectedTab === item.id
                ? "bg-blue-600 text-white"
                : "bg-gray-500 text-white hover:bg-gray-600"
            }`}
          >
            {item.title}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
        {/* <div className="sm:col-span-12 md:col-span-8 lg:col-span-8 space-y-4">
          <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center">
            <p>
              Information: All orders have been selected that have an invoice
              date recorded
            </p>
          </div>
        </div> */}
        <div className="sm:col-span-12 md:col-span-8 lg:col-span-10 space-y-4 mt-3">
          {/* First info banner - blue background with italics */}
          <div className="bg-blue-50 p-2 text-sm text-gray-700 text-center italic">
            <span>Information: We consider the </span>
            <span className="font-semibold">invoice date</span>
            <span> for orders and the </span>
            <span className="font-semibold">quality check date</span>
            <span> for co-pack and distributor transactions.</span>
            <br />
            <span className="font-semibold">Order Statuses are: </span>
            <span>
              'BBD label printed', 'Ready for shipment', 'Shipment in progress',
              'Delivered', 'Invoiced'
            </span>
          </div>
        </div>
        <div className="sm:col-span-12 md:col-span-2 lg:col-span-2 space-y-4 mt-3">
          <div className="bg-gray-200 px-2.5 pb-1 w-full h-full">
            <div className="text-xs text-gray-600">Occassion</div>
            <Select
              className="bg-gray-200"
              style={{ width: "100%" }}
              bordered={false}
              options={options}
              placeholder="All"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandNavigation;
