import React, { useEffect, useState } from "react";
import Navbar from "../../components/navigation/navbar";
import DemandNavigation from "./components/tabBar";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Supply = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640); // Tailwind's 'sm' breakpoint is 640px
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const pieData = [
    { name: "Chronopost", value: 56.79, percentage: 41.78 },
    { name: "GLS", value: 45.19, percentage: 33.25 },
    { name: "UPS", value: 33.44, percentage: 24.64 },
    { name: "DHL_Freight", value: 2.5, percentage: 2.37 },
  ];
  const pieData2 = [
    { name: "Standard", value: 119.79, percentage: 87.78 },
    { name: "Express", value: 45.19, percentage: 33.25 },
  ];

  // Data for Carriers' Delivery Mode
  const barData = [
    { name: "Chronopost", Express: 90, Standard: 10 },
    { name: "GLS", Express: 85, Standard: 15 },
    { name: "UPS", Express: 70, Standard: 30 },
    { name: "DHL_Freight", Express: 10, Standard: 100 },
  ];

  // Data for Dispatched Invoiced Orders
  const lineData = [
    { period: "2024_P01", value: 1000 },
    { period: "2024_P02", value: 5000 },
    { period: "2024_P03", value: 7000 },
    { period: "2024_P04", value: 18000 },
    { period: "2024_P05", value: 4000 },
    { period: "2024_P06", value: 3000 },
    { period: "2024_P07", value: 2500 },
    { period: "2024_P08", value: 3500 },
    { period: "2024_P09", value: 4000 },
  ];
  const COLORS = ["#1E88E5", "#FF5722", "#673AB7", "#2E7D32"];

  const columns = [
    { key: "MARS_YEAR", label: "Mars Year" },
    { key: "MARS_PERIOD", label: "Mars Period" },
    { key: "ORDERED_DATE", label: "Ordered Date" },
    { key: "DEMAND_NSV", label: "Demand NSV € (OMS)" },
    { key: "DEMAND_NSV_LY", label: "Demand NSV € LY (OMS)" },
    { key: "DEMAND_NSV_VS_LY", label: "Demand NSV vs. LY (%)" },
    { key: "NO_OF_ORDERS", label: "No of Orders (OMS)" },
    { key: "NO_OF_ORDERS_LY", label: "No of Orders (OMS) LY" },
    { key: "NO_OF_ORDERS_VS_LY", label: "No of Orders vs. LY (%)" },
    { key: "DEMAND_AOV", label: "Demand AOV € (OMS)" },
  ];
  const deliveryData = [
    {
      carrierName: "Chronopost",
      mode: "Express",
      orderCount: 3540,
      carrierPackages: 4121,
      orderTotal: "2.60%",
    },
    {
      carrierName: "Chronopost",
      mode: "Standard",
      orderCount: 53250,
      carrierPackages: 57760,
      orderTotal: "39.18%",
    },
    {
      carrierName: "DHL Freight",
      mode: "Express",
      orderCount: 3,
      carrierPackages: 3,
      orderTotal: "0.00%",
    },
    {
      carrierName: "DHL Freight",
      mode: "Standard",
      orderCount: 498,
      carrierPackages: 3257,
      orderTotal: "0.37%",
    },
    {
      carrierName: "GLS",
      mode: "Express",
      orderCount: 4763,
      carrierPackages: 5230,
      orderTotal: "3.50%",
    },
    {
      carrierName: "GLS",
      mode: "Standard",
      orderCount: 40425,
      carrierPackages: 42374,
      orderTotal: "29.74%",
    },
    {
      carrierName: "UPS",
      mode: "Express",
      orderCount: 8407,
      carrierPackages: 9643,
      orderTotal: "6.19%",
    },
    {
      carrierName: "UPS",
      mode: "Standard",
      orderCount: 25030,
      carrierPackages: 28099,
      orderTotal: "18.42%",
    },
  ];

  const countryData = [
    {
      carrierName: "DHL_Freight",
      country: "AT",
      carrierPackages: 24,
      shippedNSV: 10495.0,
    },
    {
      carrierName: "GLS",
      country: "AT",
      carrierPackages: 4818,
      shippedNSV: 339705.77,
    },
    {
      carrierName: "UPS",
      country: "AT",
      carrierPackages: 2508,
      shippedNSV: 237201.13,
    },
    {
      carrierName: "Chronopost",
      country: "BE",
      carrierPackages: 443,
      shippedNSV: 103338.55,
    },
    {
      carrierName: "DHL_Freight",
      country: "BE",
      carrierPackages: 46,
      shippedNSV: 15549.5,
    },
    {
      carrierName: "UPS",
      country: "BE",
      carrierPackages: 7435,
      shippedNSV: 623253.27,
    },
  ];
  const formatNumber = (value: any) => {
    return value.toLocaleString();
  };

  const formatCurrency = (value: any) => {
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };
  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <Navbar />
      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <DemandNavigation />
      </div>
      <div className="p-4 md:p-6">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          <div className="sm:col-span-12 md:col-span-4">
            <div className="bg-white p-4 rounded-lg shadow h-[400px]">
              <h3 className="text-lg font-semibold mb-4 text-gray-700">
                Carrier Invoiced Orders Count
              </h3>
              <div className="w-full h-[320px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieData}
                      cx="50%"
                      cy="50%"
                      outerRadius={isMobile ? 50 : 100} // Adjust radius based on screen size
                      dataKey="value"
                      label={({ name, percentage }) =>
                        `${name} (${percentage.toFixed(2)}%)`
                      }
                    >
                      {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Carriers' Delivery Mode */}
          <div className="sm:col-span-12 md:col-span-4">
            <div className="bg-white p-4 rounded-lg shadow h-[400px]">
              <h3 className="text-lg font-semibold mb-4 text-gray-700">
                Carriers' Delivery Mode (Invoiced Orders)
              </h3>
              <div className="w-full h-[320px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={barData} barSize={40}>
                    <CartesianGrid strokeDasharray="3 3" />{" "}
                    {/* Adds dotted grid lines */}
                    <XAxis dataKey="name" axisLine={false} />
                    <YAxis
                      axisLine={false}
                      tickFormatter={(value) => `${value}%`}
                      label={{
                        value: "Count of Order number",
                        angle: -90,
                        position: "insideLeft",
                        dx: 0, // Slight horizontal adjustment
                        dy: 20, // Moves the label downward
                        style: { textAnchor: "middle", fontSize: "12px" }, // Optional styling
                      }}
                    />
                    <Tooltip cursor={false} />
                    <Legend />
                    <Bar
                      style={{ padding: 10 }}
                      className="www"
                      dataKey="Express"
                      stackId="a"
                      fill="#2196F3"
                    />
                    <Bar dataKey="Standard" stackId="a" fill="#1a237e" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          {/* Dispatched Invoiced Orders */}
          <div className="sm:col-span-12 md:col-span-4">
            <div className="bg-white p-4 rounded-lg shadow h-[400px]">
              <h3 className="text-lg font-semibold mb-4 text-gray-700">
                Dispatched Invoiced Orders
              </h3>
              <div className="w-full h-[320px]">
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={lineData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="period"
                      angle={-90}
                      textAnchor="end"
                      height={100}
                      axisLine={false} // Hides the X-axis line
                    />
                    <YAxis
                      axisLine={false} // Hides the Y-axis line
                      tickLine={false} // Hides tick lines but keeps values
                      tickFormatter={(value) => `${value / 1000}k`}
                      label={{
                        value: "Count of Dispatched to Carried",
                        angle: -90,
                        position: "insideLeft",
                        dx: 0, // Slight horizontal adjustment
                        dy: 20, // Moves the label downward
                        style: { textAnchor: "middle", fontSize: "12px" }, // Optional styling
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#2196F3"
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>

          <div className="sm:col-span-12 md:col-span-4">
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold text-gray-700">
                  Carrier Delivery Count (Invoiced Orders)
                </h2>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">
                        CarrierName
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">
                        Mode
                      </th>
                      <th className="px-4 py-2 text-right text-xs font-medium text-gray-500">
                        Order Count
                      </th>
                      <th className="px-4 py-2 text-right text-xs font-medium text-gray-500">
                        Carrier Packages
                      </th>
                      <th className="px-4 py-2 text-right text-xs font-medium text-gray-500">
                        % of Order Total
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {deliveryData.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row.carrierName}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row.mode}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600 text-right">
                          {formatNumber(row.orderCount)}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600 text-right">
                          {formatNumber(row.carrierPackages)}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600 text-right">
                          {row.orderTotal}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600 text-white">
                      <td colSpan={2} className="px-4 py-2 text-sm font-bold">
                        Total
                      </td>
                      <td className="px-4 py-2 text-sm font-bold text-right">
                        135915
                      </td>
                      <td className="px-4 py-2 text-sm font-bold text-right">
                        150487
                      </td>
                      <td className="px-4 py-2 text-sm font-bold text-right">
                        100.00%
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-4">
            <div className="bg-white p-4 rounded-lg shadow h-[400px]">
              <h3 className="text-lg font-semibold mb-4 text-gray-700">
                Carrier Invoiced Orders Count
              </h3>
              <div className="w-full h-[320px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={pieData2}
                      cx="50%"
                      cy="50%"
                      outerRadius={isMobile ? 50 : 100} // Adjust radius based on screen size
                      dataKey="value"
                      label={({ name, percentage }) =>
                        `${name} (${percentage.toFixed(2)}%)`
                      }
                    >
                      {pieData2.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                      ))}
                    </Pie>
                    <Tooltip /> <Legend />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-4">
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold text-gray-700">
                  Carrier by Country Invoiced NSV
                </h2>
              </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">
                        CarrierName
                      </th>
                      <th className="px-4 py-2 text-left text-xs font-medium text-gray-500">
                        Country
                      </th>
                      <th className="px-4 py-2 text-right text-xs font-medium text-gray-500">
                        Carrier Packages
                      </th>
                      <th className="px-4 py-2 text-right text-xs font-medium text-gray-500">
                        Shipped NSV
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {countryData.map((row, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row.carrierName}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600">
                          {row.country}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600 text-right">
                          {formatNumber(row.carrierPackages)}
                        </td>
                        <td className="px-4 py-2 text-sm text-gray-600 text-right">
                          {formatCurrency(row.shippedNSV)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-blue-600 text-white">
                      <td colSpan={2} className="px-4 py-2 text-sm font-bold">
                        Total
                      </td>
                      <td className="px-4 py-2 text-sm font-bold text-right">
                        150487
                      </td>
                      <td className="px-4 py-2 text-sm font-bold text-right">
                        {formatCurrency(13242915.71)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Supply;
