const API = {
  // WEB_URL: "http://localhost:3000",
  // BASE_URL: "http://localhost:8080/",
  BASE_URL: "https://stage.api.maverickbackendeu.mms.com/",

  LOGIN: "auth/microsoft", //POST
  EU_ALLORDER: "eu-all-orders/", //GET POST
  FILTERS: "dashboard/filter-options", //GET
  FILTERSBYCHANNEL: "dashboard/filter-options-by-saleschannel", //POST
};
export default API;
