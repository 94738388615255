import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the filter state interface
interface FilterState {
  filters: any[];
  lastFetched: number | null;
}

const initialState: FilterState = {
  filters: [],
  lastFetched: null,
};

const FilterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<any[]>) => {
      state.filters = action.payload;
      state.lastFetched = Date.now();
    },
    clearFilters: (state) => {
      state.filters = [];
      state.lastFetched = null;
    },
  },
});

export const { setFilters, clearFilters } = FilterSlice.actions;
export default FilterSlice.reducer;
