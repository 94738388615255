// // src/components/AnalyticCards/AnalyticCard.tsx
// import React from "react";
// import { AnalyticsCardProps } from "./types";

// export const AnalyticCard: React.FC<AnalyticsCardProps> = ({
//   value,
//   label,
//   progressBar,
// }) => {
//   return (
//     <div className="border border-gray-200 p-4 rounded-lg bg-white">
//       <div className="flex flex-col">
//         {progressBar ? (
//           <>
//             <div className="mb-2">
//               <div className="w-full h-2 bg-gray-200 rounded-full">
//                 <div
//                   className="h-full rounded-full transition-all duration-300"
//                   style={{
//                     width: `${progressBar.percentage}%`,
//                     backgroundColor: progressBar.color,
//                   }}
//                 />
//               </div>
//             </div>
//             <div className="text-gray-600 text-sm">
//               {label}: {progressBar.percentage}%
//             </div>
//           </>
//         ) : (
//           <>
//             <div className="text-xl font-bold mb-1 text-[#007bff]">{value}</div>{" "}
//             <div className="text-gray-600 text-sm">{label}</div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// src/components/AnalyticCards/AnalyticCard.tsx
import React from "react";
import { AnalyticsCardProps } from "./types";

export const AnalyticCard: React.FC<AnalyticsCardProps> = ({
  value,
  label,
  icon,
  progressBar,
}) => {
  return (
    <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-[0_4px_8px_rgba(0,0,0,0.1)] transform transition-transform duration-300 ease-in-out hover:scale-105">
      <div className="flex items-center gap-3">
        {icon && <div className="flex-shrink-0 mt-1">{icon}</div>}
        <div className="flex-1">
          {progressBar ? (
            <div>
              <div className="mb-3">
                <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className="h-full rounded-full transition-all duration-300"
                    style={{
                      width: `${progressBar.percentage}%`,
                      backgroundColor: progressBar.color,
                    }}
                  />
                </div>
              </div>
              <div className="text-sm text-gray-600">
                {label}:{" "}
                <span className="text-gray-900 font-medium">
                  {progressBar.percentage}%
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className="text-[24px] font-bold text-[#007bff] mb-1">
                {value}
              </div>
              <div className="text-sm text-gray-600">{label}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
