import React, { useState } from "react";
import DemandNavigation from "./components/tabBar";
import Navbar from "../../components/navigation/navbar";
import { AnalyticCard } from "../../components/analyticsCard/analyticsCard";
import { ArrowUpDown } from "lucide-react";
import moment from "moment";

const Performance = () => {
  const [isLoading, setIsLoading] = useState(false);

  const metrics = [
    {
      value: "3.0%",
      label: "Actual vs Plan",
    },
    {
      value: "141.01K",
      label: "Actual vs Plan (abs)",
    },
    {
      value: "0.2%",
      label: "Actual vs YEE",
    },
    {
      value: "13.51K",
      label: "Actual vs YEE (abs)",
    },
  ];
  const columns = [
    { key: "week", label: "Week" },
    { key: "actuals", label: "Actuals" },
    { key: "yee", label: "YEE" },
    { key: "vs_YEE_ABS", label: "vs YEE (abs)" },
    { key: "vs_YEE_Percentage", label: "vs YEE (%)" },
    { key: "invoiced_LY", label: "Invoiced LY" },
    { key: "vs_LY_ABS", label: "vs LY (abs)" },
    { key: "vs_LY", label: "vs LY" },
    { key: "plan", label: "Plan" },
    { key: "vs_Plan_ABS", label: "vs Plan (abs)" },
    { key: "vs_plan_Percentage", label: "vs Plan (%)" },
  ];
  const columns2 = [
    { key: "b2c_by_country", label: "B2C by Country" },
    { key: "actuals", label: "Actuals" },
    { key: "invoiced_LY", label: "Invoiced LY" },
    { key: "vs_LY_ABS", label: "vs LY (abs)" },
    { key: "vs_LY", label: "vs LY" },
  ];
  const columns3 = [
    { key: "channel", label: "Channel" },
    { key: "actuals", label: "Actuals" },
    { key: "invoiced_LY", label: "Invoiced LY" },
    { key: "vs_LY_ABS", label: "vs LY (abs)" },
    { key: "vs_LY", label: "vs LY" },
  ];

  const dummyData = [
    {
      week: 2024,
      actuals: 1,
      yee: "2023-12-31",
      vs_YEE_ABS: 5647,
      vs_YEE_Percentage: 5741,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
      plan: 3.8,
      vs_Plan_ABS: 69,
      vs_plan_Percentage: 69,
    },
    {
      week: 2024,
      actuals: 1,
      yee: "2023-12-31",
      vs_YEE_ABS: 5647,
      vs_YEE_Percentage: 5741,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
      plan: 3.8,
      vs_Plan_ABS: 69,
      vs_plan_Percentage: 69,
    },
    {
      week: 2024,
      actuals: 1,
      yee: "2023-12-31",
      vs_YEE_ABS: 5647,
      vs_YEE_Percentage: 5741,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
      plan: 3.8,
      vs_Plan_ABS: 69,
      vs_plan_Percentage: 69,
    },
    {
      week: 2024,
      actuals: 1,
      yee: "2023-12-31",
      vs_YEE_ABS: 5647,
      vs_YEE_Percentage: 5741,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
      plan: 3.8,
      vs_Plan_ABS: 69,
      vs_plan_Percentage: 69,
    },
    {
      week: 2024,
      actuals: 1,
      yee: "2023-12-31",
      vs_YEE_ABS: 5647,
      vs_YEE_Percentage: 5741,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
      plan: 3.8,
      vs_Plan_ABS: 69,
      vs_plan_Percentage: 69,
    },
    // Add more rows as necessary
  ];
  const dummyData2 = [
    {
      b2c_by_country: "AT",
      actuals: 1,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
    },
    {
      b2c_by_country: "BE",
      actuals: 1,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
    },
  ];
  const dummyData3 = [
    {
      channel: "B2B",
      actuals: 1,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
    },
    {
      channel: "B2C",
      actuals: 1,
      invoiced_LY: -1.6,
      vs_LY_ABS: 82,
      vs_LY: 79,
    },
    // Add more rows as necessary
  ];

  const totalRow: any = {
    week: "Total",
    actuals: 345,
    yee: "",
    vs_YEE_ABS: 13509100,
    vs_YEE_Percentage: 13111972,
    invoiced_LY: "",
    vs_LY_ABS: 3.0,
    vs_LY: "",
    plan: "",
    vs_Plan_ABS: 0.2,
    vs_plan_Percentage: 96,
  };
  const totalRow2: any = {
    actuals: 3435,
    invoiced_LY: 3435,
    vs_LY_ABS: 3.0,
    vs_LY: 3435,
  };

  const getPercentageColor = (value: any) => {
    const numValue = parseFloat(value);
    if (numValue > 0) return "bg-green-100 text-green-800";
    if (numValue < 0) return "bg-red-100 text-red-800";
    return "text-gray-600";
  };

  const formatCurrency = (value: any) => {
    if (isNaN(value)) return "-";
    return new Intl.NumberFormat("en-EU", {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercentage = (value: any) => {
    if (isNaN(value)) return "-";
    return `${value.toFixed(1)}%`;
  };
  const CircularProgress = ({ percentage }: { percentage: number }) => {
    const radiusOuter = 30; // Outer circle radius
    const radiusInner = 25; // Inner circle radius
    const circumferenceOuter = 2 * Math.PI * radiusOuter;
    const circumferenceInner = 2 * Math.PI * radiusInner;
    const offsetOuter =
      circumferenceOuter - (Math.abs(percentage) / 100) * circumferenceOuter;
    const offsetInner =
      circumferenceInner - (Math.abs(percentage) / 100) * circumferenceInner;

    return (
      <div className="relative w-24 h-24">
        <svg className="transform -rotate-90 w-24 h-24">
          {/* Outer Background Circle */}
          <circle
            className="text-gray-300"
            strokeWidth="6"
            stroke="currentColor"
            fill="transparent"
            r={radiusOuter}
            cx="48"
            cy="48"
          />
          {/* Outer Progress Circle */}
          <circle
            className="text-purple-600"
            strokeWidth="6"
            strokeDasharray={circumferenceOuter}
            strokeDashoffset={offsetOuter}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r={radiusOuter}
            cx="48"
            cy="48"
          />
          {/* Inner Background Circle */}
          <circle
            className="text-gray-200"
            strokeWidth="6"
            stroke="currentColor"
            fill="transparent"
            r={radiusInner}
            cx="48"
            cy="48"
          />
          {/* Inner Progress Circle */}
          <circle
            className="text-purple-400"
            strokeWidth="6"
            strokeDasharray={circumferenceInner}
            strokeDashoffset={offsetInner}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r={radiusInner}
            cx="48"
            cy="48"
          />
        </svg>
        {/* Percentage Text */}
        <span className="absolute inset-0 flex items-center justify-center text-xs font-bold text-purple-600">
          {percentage}%
        </span>
      </div>
    );
  };

  // Financial data
  const data = {
    actualValue: 1083985,
    yeeValue: 1230169,
    lyValue: 2132654,
    differenceYEE: -146185,
    differenceLY: -146185,
    percentage: -70.88,
    percentage2: -80.12,
  };

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <Navbar />
      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <DemandNavigation />
      </div>
      <div className="p-4 md:p-6">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
          {/* Left Section */}
          <div className="sm:col-span-12 md:col-span-3 lg:col-span-2 space-y-4">
            {metrics.map((metric, index) => (
              <AnalyticCard
                key={index}
                value={metric.value}
                label={metric.label}
              />
            ))}
          </div>

          <div className="sm:col-span-12 md:col-span-9 lg:col-span-10 space-y-4">
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold text-gray-700">
                  Weekly NSV Performance vs Plan & LY
                </h2>
              </div>
              <div className="overflow-x-auto">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        {columns.map(({ key, label }) => (
                          <th
                            key={key}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                          >
                            <div className="flex items-center space-x-1">
                              <span>{label}</span>
                              <ArrowUpDown className="h-4 w-4" />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {dummyData.map((row: any, index: any) => (
                        <tr key={index} className="hover:bg-gray-50">
                          {columns.map(({ key }) => (
                            <td
                              key={key}
                              className={`px-6 py-4 whitespace-nowrap text-sm text-gray-600 ${
                                key.includes("vs")
                                  ? getPercentageColor(row[key])
                                  : ""
                              }`}
                            >
                              {key === "yee"
                                ? moment(row[key]).format("DD MMMM YYYY")
                                : key.includes("vs") &&
                                  key.includes("Percentage")
                                ? formatPercentage(row[key])
                                : key.includes("vs") &&
                                  !key.includes("Percentage")
                                ? formatCurrency(row[key])
                                : row[key] || "-"}
                            </td>
                          ))}
                        </tr>
                      ))}
                      <tr className="bg-blue-600">
                        {columns.map(({ key }) => (
                          <td
                            key={key}
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white"
                          >
                            {key.includes("vs") && key.includes("Percentage")
                              ? formatPercentage(totalRow[key])
                              : key.includes("vs") &&
                                !key.includes("Percentage")
                              ? formatCurrency(totalRow[key])
                              : totalRow[key] || "-"}
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 space-y-4">
            <div className="bg-white rounded-lg shadow">
              <div className="p-4 border-b border-gray-200">
                <h2 className="text-lg font-semibold text-gray-700">
                  Weekly NSV Performance by Channel
                </h2>
              </div>
              <div className="overflow-x-auto">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        {columns2.map(({ key, label }) => (
                          <th
                            key={key}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                          >
                            <div className="flex items-center space-x-1">
                              <span>{label}</span>
                              <ArrowUpDown className="h-4 w-4" />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {dummyData2.map((row, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.b2c_by_country}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            P{row.actuals}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row.invoiced_LY
                              )}`}
                            >
                              {formatPercentage(row.vs_LY_ABS)}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.vs_LY_ABS}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.vs_LY}
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-blue-600">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          Total Digit EU
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow2.actuals)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow2.invoiced_LY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-3 py-1 text-sm font-medium text-white">
                            {formatPercentage(totalRow2.vs_LY_ABS)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-3 py-1 text-sm font-medium text-white">
                            {formatPercentage(totalRow2.vs_LY)}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
          <div className="sm:col-span-12 md:col-span-6 lg:col-span-6 space-y-4">
            {/* <div className="bg-white p-6 rounded-lg shadow">
              <div className="flex items-start justify-between">
                <div>
                  <p className="text-3xl font-semibold">
                    {formatCurrency(data.actualValue)}
                  </p>
                  <div>Actual</div>
                </div>
                <div>
                  <p>vs YEE</p>
                  <p className="text-xl text-gray-500">
                    {formatCurrency(data.yeeValue)}
                  </p>
                  <p className="text-sm text-gray-500 text-center">YEE</p>
                  <br />
                  <p>vs LY</p>
                  <p className="text-xl text-gray-500">
                    {formatCurrency(data.lyValue)}
                  </p>
                  <p className="text-sm text-gray-500 text-center">LY</p>
                </div>
                <div>
                  <p className="text-xl text-gray-500">
                    {formatCurrency(data.difference)}
                  </p>
                  <p className="text-sm text-gray-500">Actual vs YEE (abs)</p>
                  <br />
                  <p className="text-xl text-gray-500">
                    {formatCurrency(data.difference)}
                  </p>
                  <p className="text-sm text-gray-500">Actual vs LY (abs)</p>
                </div>
                <div className="ml-8">
                  <CircularProgress percentage={data.percentage} />
                </div>
              </div> */}
            <div className="bg-white p-4 md:p-6 rounded-lg shadow">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
                {/* Left Column - Actual */}
                <div className="flex flex-col justify-center space-y-1 text-center sm:text-left">
                  <p className="text-lg md:text-xl font-semibold">
                    {formatCurrency(data.actualValue)}
                  </p>
                  <p className="text-sm text-gray-500">Actual</p>
                </div>

                {/* Middle Column - YEE and LY values */}
                <div className="flex flex-col justify-center space-y-4 md:space-y-6 text-center sm:text-left">
                  <div>
                    {/* <p className="text-sm text-gray-700 font-medium">vs YEE</p> */}
                    <p className="text-base md:text-lg text-gray-500">
                      {formatCurrency(data.yeeValue)}
                    </p>
                    <p className="text-xs text-gray-500">YEE</p>
                  </div>
                  <div>
                    {/* <p className="text-sm text-gray-700 font-medium">vs LY</p> */}
                    <p className="text-base md:text-lg text-gray-500">
                      {formatCurrency(data.lyValue)}
                    </p>
                    <p className="text-xs text-gray-500">LY</p>
                  </div>
                </div>

                {/* Right Column - Differences */}
                <div className="flex flex-col justify-center space-y-4 md:space-y-6 text-center sm:text-left">
                  <div>
                    <p className="text-base md:text-lg text-gray-500">
                      {formatCurrency(data.differenceYEE)}
                    </p>
                    <p className="text-xs text-gray-500">Actual vs YEE (abs)</p>
                  </div>
                  <div>
                    <p className="text-base md:text-lg text-gray-500">
                      {formatCurrency(data.differenceLY)}
                    </p>
                    <p className="text-xs text-gray-500">Actual vs LY (abs)</p>
                  </div>
                </div>

                {/* Progress Circle */}
                <div className="flex flex-col justify-center lg:justify-end items-center mt-4 lg:mt-0">
                  <CircularProgress percentage={data.percentage} />
                  <CircularProgress percentage={data.percentage2} />
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow">
              <div className="overflow-x-auto">
                {isLoading ? (
                  <div>Loading...</div>
                ) : (
                  <table className="w-full">
                    <thead className="bg-gray-50">
                      <tr>
                        {columns3.map(({ key, label }) => (
                          <th
                            key={key}
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                          >
                            <div className="flex items-center space-x-1">
                              <span>{label}</span>
                              <ArrowUpDown className="h-4 w-4" />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {dummyData3.map((row, index) => (
                        <tr key={index} className="hover:bg-gray-50">
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.channel}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            P{row.actuals}
                          </td>

                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-3 py-1 rounded-full text-xs font-medium ${getPercentageColor(
                                row.invoiced_LY
                              )}`}
                            >
                              {formatPercentage(row.invoiced_LY)}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.vs_LY_ABS}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                            {row.vs_LY}
                          </td>
                        </tr>
                      ))}
                      <tr className="bg-blue-600">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-bold text-white">
                          Total Digit EU
                        </td>{" "}
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow2.actuals)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-white">
                          {formatCurrency(totalRow2.invoiced_LY)}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-3 py-1 text-sm font-medium text-white">
                            {formatPercentage(totalRow2.vs_LY_ABS)}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-3 py-1 text-sm font-medium text-white">
                            {formatPercentage(totalRow2.vs_LY)}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Performance;
