import { Form, Select } from "antd";
import {
  Activity,
  BanknoteIcon,
  CalendarIcon,
  LayoutDashboard,
  PackageIcon,
  Percent,
  PercentIcon,
  ShoppingCartIcon,
  TrendingUpIcon,
  TruckIcon,
  UserPlusIcon,
  UsersIcon,
  XCircleIcon,
} from "lucide-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { AnalyticCard } from "../../components/analyticsCard/analyticsCard";
import NavbarCCM from "../../components/navigation/ccmnavbar";
import API from "../../config/api";
import { setFilters } from "../../redux/slices/filterSlice";
import { GET, POST } from "../../utils/apiCalls";

const salesData = [
  { name: "Jan", sales: 200 },
  { name: "Feb", sales: 300 },
  { name: "Mar", sales: 600 },
  { name: "Apr", sales: 800 },
  { name: "May", sales: 700 },
];
const salesValues = salesData.map((item) => item.sales);
interface FilterValues {
  year: string[];
  periods: string[];
  weeks: string[];
  country: string[];
  channel: string[];
  salesChannel: string[];
}

export const CCMDashboard: React.FC = () => {
  const { filters, lastFetched } = useSelector((state: any) => state.Filter);
  const dispatch = useDispatch();
  const [salesChannel2Options, setSalesChannel2Options] = useState<any>({});
  const [isSalesChannel2Disabled, setIsSalesChannel2Disabled] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    getFilters();
  }, [1]);
  const getFilters = async () => {
    try {
      if (filters.length > 0) {
        return;
      }
      const response: any = await GET(API.FILTERS);

      if (response.length) {
        dispatch(setFilters(response));
      }

      const initialValues: FilterValues = {
        year: [],
        periods: [],
        weeks: [],
        country: [],
        channel: [],
        salesChannel: [],
      };
      form.setFieldsValue(initialValues);
    } catch (error) {
      console.log(error);
    }
  };

  const FilterBox = ({
    label,
    defaultValue,
    disabled,
    name,
    options = [
      {
        label: "All",
        value: "All",
      },
    ],
  }: any) => {
    return (
      <div className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none">
        <div className="w-full bg-white rounded shadow-sm border">
          <div className="p-0.5">
            <div className="min-w-0 flex-1">
              <div className="text-xs text-gray-500 truncate pl-1 pt-1">
                {label}
              </div>
              <Form.Item
                name={name}
                className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none mb-0"
              >
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  style={{ width: "100%" }}
                  disabled={disabled}
                  bordered={false}
                  filterOption={(input: any, option: any) =>
                    (option?.label ?? "")
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={options}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const leftMetrics = [
    {
      icon: <PackageIcon className="w-5 h-5 text-amber-500" />,
      value: "$30,000",
      label: "Total Orders",
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
      value: "$5,000",
      label: "Total NSV Invoiced",
    },
    {
      icon: <TruckIcon className="w-5 h-5 text-green-600" />,
      value: "15",
      label: "Shipped Orders",
    },
    {
      icon: <XCircleIcon className="w-5 h-5 text-red-500" />,
      value: "5",
      label: "Cancelled Orders",
    },
    {
      icon: <CalendarIcon className="w-5 h-5 text-purple-500" />,
      value: "5",
      label: "Period Days Remaining",
    },
    {
      icon: <UserPlusIcon className="w-5 h-5 text-blue-500" />,
      value: "5",
      label: "New Customers during this Period",
    },
  ];

  const rightMetrics = [
    {
      icon: <UsersIcon className="w-5 h-5 text-green-600" />,
      value: "75%",
      label: "Returning Customers during Period",
    },
    {
      icon: <BanknoteIcon className="w-5 h-5 text-blue-600" />,
      value: "$12,000",
      label: "Total NSV Demand",
    },
    {
      icon: <TrendingUpIcon className="w-5 h-5 text-amber-500" />,
      value: "18%",
      label: "Orders invoiced vs Demand",
    },
    {
      icon: <PercentIcon className="w-5 h-5 text-green-600" />,
      value: "90%",
      label: "% of Orders Invoiced",
    },
    {
      icon: <ShoppingCartIcon className="w-5 h-5 text-blue-600" />,
      value: "4",
      label: "Total Products Sold",
    },
  ];
  const handleFormChange = async (changedValues: any, allValues: any) => {
    try {
      if (changedValues.salesChannel !== undefined) {
        if (
          changedValues.salesChannel &&
          changedValues.salesChannel.length > 0
        ) {
          const response = await POST(API.FILTERSBYCHANNEL, {
            channel: changedValues.salesChannel,
          });
          setSalesChannel2Options(response);
          setIsSalesChannel2Disabled(false);
        } else {
          form.setFieldsValue({
            salesChannel: [],
            salesChannel2: [],
          });
          setSalesChannel2Options({});
          setIsSalesChannel2Disabled(true);
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 font-sans">
      <NavbarCCM />

      <div className="flex items-center gap-2 bg-white border-b border-gray-200 px-6 py-4">
        <LayoutDashboard className="text-gray-600" />
        <h1 className="text-xl font-medium">
          Dashboard:{" "}
          <span className="font-bold">
            As of {moment(new Date("2024-11-12")).format("DD MMM YYYY")}
          </span>
        </h1>
      </div>

      <div className="p-4 md:p-6">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
          {/* Left Section */}
          <div className="sm:col-span-12 md:col-span-3 lg:col-span-3 space-y-4">
            {leftMetrics.map((metric, index) => (
              <AnalyticCard
                key={index}
                icon={metric.icon}
                value={metric.value}
                label={metric.label}
              />
            ))}
            {rightMetrics.map((metric, index) => (
              <AnalyticCard
                key={index}
                icon={metric.icon}
                value={metric.value}
                label={metric.label}
              />
            ))}
          </div>

          {/* Middle Section */}
          <div className="sm:col-span-12 md:col-span-9 lg:col-span-9 p-4">
            <h2 className="text-2xl font-semibold mb-6">
              EU Digital Business Position
            </h2>
            {/* filters */}
            <div className="w-full overflow-x-auto">
              {/* Container with horizontal scrolling on mobile */}
              {/* <div className="max-w-[100vw] "> */}
              {/* Inner container with padding and spacing */}
              <Form
                form={form}
                onValuesChange={handleFormChange}
                className="w-full overflow-x-auto"
              >
                <div className="min-w-max pb-5">
                  {/* Flex container for filters */}
                  <div className="flex flex-row gap-4">
                    {filters?.map((filter: any) => (
                      <FilterBox
                        key={filter.label}
                        name={filter.name}
                        label={filter.label}
                        disabled={filter?.disabled}
                        defaultValue={filter.defaultValue}
                        options={filter.options}
                      />
                    ))}
                    <Form.Item
                      name="salesChannel2"
                      className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none mb-0"
                    >
                      <div className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none">
                        <div className="w-full bg-white rounded shadow-sm border">
                          <div className="p-0.5">
                            <div className="min-w-0 flex-1">
                              <div className="text-xs text-gray-500 truncate pl-1 pt-1">
                                Sales Channel 2
                              </div>
                              <Select
                                showSearch
                                allowClear
                                mode="multiple"
                                style={{ width: "100%" }}
                                disabled={isSalesChannel2Disabled}
                                bordered={false}
                                filterOption={(input: any, option: any) =>
                                  (option?.label ?? "")
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={salesChannel2Options.options}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form.Item>
                  </div>
                </div>
              </Form>
              {/* </div> */}
            </div>
            <div className="border border-gray-200 rounded-lg shadow-sm p-4">
              <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                <div className="text-[#a17478] font-bold">NSV</div>
                <div className="bg-[#fde2ff] p-1 rounded-[50%] text-white">
                  <Percent color="#954e9a" size={20} />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 pt-5">
                {/* First Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Invoice NSV
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>

                {/* Second Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Demand
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>

                {/* Third Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      DTC Demand
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>

                {/* Fourth Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Pipeline
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>

                {/* Fifth Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Refunded
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-gray-200 rounded-lg shadow-sm p-4 mt-2">
              <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                <div className="text-[#a17478] font-bold">Orders</div>
                <div className="bg-[#fde2ff] p-1 rounded-[50%] text-white">
                  <Percent color="#954e9a" size={20} />
                </div>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 pt-5">
                {/* First Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Total Orders
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>

                {/* Second Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      DTC Orders
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>

                {/* Third Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Orders Shipped
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>

                {/* Fourth Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Orders cancelled
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>

                {/* Fifth Graph */}
                <div className="w-full">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Orders refunded
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div className="h-16">
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
              <div className="border border-gray-200 rounded-lg shadow-sm p-4 mt-2">
                <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                  <div className="text-[#a17478] font-bold">DTC Traffic</div>
                  <div className="bg-[#d7e1f3] p-1 rounded-[50%] text-white">
                    <Activity color="#6895e0" size={20} />
                  </div>
                </div>
                {/* First Graph */}
                <div className="w-full pt-3">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Total Orders
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div>
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>
              </div>
              <div className="border border-gray-200 rounded-lg shadow-sm p-4 mt-2">
                <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                  <div className="text-[#a17478] font-bold">
                    DTC Conversion Rate
                  </div>
                  <div className="bg-[#d7e1f3] p-1 rounded-[50%] text-white">
                    <Activity color="#6895e0" size={20} />
                  </div>
                </div>
                {/* First Graph */}
                <div className="w-full pt-3">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Total Orders
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div>
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>
              </div>
              <div className="border border-gray-200 rounded-lg shadow-sm p-4 mt-2">
                <div className="flex justify-between items-center border-b border-gray-200 pb-2">
                  <div className="text-[#a17478] font-bold">DTC AGV</div>
                </div>
                {/* First Graph */}
                <div className="w-full pt-3">
                  <div className="flex justify-between mb-1">
                    <div className="text-sm text-gray-600">
                      {/* {salesValues.title} */}
                      Total Orders
                    </div>
                    <div className="text-sm text-red-500">-1.7%</div>
                  </div>
                  <div className="text-sm font-semibold mb-2">$450.4K</div>
                  <div>
                    {/* Reduced height for better proportion */}
                    <Sparklines data={salesValues}>
                      <SparklinesLine color="#f48427" />
                    </Sparklines>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right Section */}
          {/* <div className="sm:col-span-12 md:col-span-3 lg:col-span-3 space-y-4">
            
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CCMDashboard;
