import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "7616c900-0bc0-4e7f-b1b0-37e1ba0a29f0",
    redirectUri: "https://stage.maverickbackendeu.mms.com/",
    // redirectUri: "http://localhost:3000/",
    scopes: ["user.read"],
    // clientId: "4daff424-f696-4b99-9467-0014e824a25f",
    // redirectUri: "http://localhost:3000",
    // scopes: ["user.read"],

    authority:
      "https://login.microsoftonline.com/2fc13e34-f03f-498b-982a-7cb446e25bc6",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const azureAuthClient = new PublicClientApplication(msalConfig);

// clientId: '4daff424-f696-4b99-9467-0014e824a25f',
// redirectUri: 'http://localhost:3000',
// scopes: ['user.read'],
