import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Store, PersistedStore } from "./redux/store";

import Login from "./pages/login";
import Dashboard from "./pages/omsdashboard";
import EuOrders from "./pages/euOrders";
import OrderView from "./pages/euOrders/orderView";
import Demand from "./pages/demand";
import CCMDashboard from "./pages/ccmdashboard";
import Sales from "./pages/sales";
import Supply from "./pages/supply";
import Performance from "./pages/performance";
import { useSelector } from "react-redux";

const AuthRedirect = ({ children }: any) => {
  const tikon = useSelector((state: any) => state.Auth.token);
  return tikon ? <Navigate to="/oms-dashboard" replace /> : children;
};

const ProtectRedirect = ({ children }: any) => {
  const tikon = useSelector((state: any) => state.Auth.token);
  return !tikon ? <Navigate to="/login" replace /> : children;
};

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const redirectPath = params.get("redirect");
    if (redirectPath) {
      navigate(redirectPath);
    }
  }, [navigate]);
  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={PersistedStore}>
        <Routes>
          <Route
            path="/"
            element={
              <AuthRedirect>
                <Navigate to="/login" replace />
              </AuthRedirect>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/oms-dashboard"
            element={
              <ProtectRedirect>
                <Dashboard />
              </ProtectRedirect>
            }
          />
          <Route
            path="/ccm-dashboard"
            element={
              <ProtectRedirect>
                <CCMDashboard />
              </ProtectRedirect>
            }
          />
          <Route
            path="/eu-orders"
            element={
              <ProtectRedirect>
                <EuOrders />
              </ProtectRedirect>
            }
          />
          <Route
            path="/eu-orders/:orderId"
            element={
              <ProtectRedirect>
                <OrderView />
              </ProtectRedirect>
            }
          />
          <Route
            path="/demand"
            element={
              <ProtectRedirect>
                <Demand />
              </ProtectRedirect>
            }
          />
          <Route
            path="/sales"
            element={
              <ProtectRedirect>
                <Sales />
              </ProtectRedirect>
            }
          />
          <Route
            path="/supply"
            element={
              <ProtectRedirect>
                <Supply />
              </ProtectRedirect>
            }
          />
          <Route
            path="/performance"
            element={
              <ProtectRedirect>
                <Performance />
              </ProtectRedirect>
            }
          />
        </Routes>
      </PersistGate>
    </Provider>
  );
}

export default App;
