import React, { useState } from "react";
import { Select } from "antd";

const DemandNavigation = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedRoundTab, setSelectedRoundTab] = useState(1);
  const [headingTxt, setHeadingTxt] = useState(false);

  const tabCard1 = [
    { id: 1, title: "Daily Sales" },
    { id: 2, title: "Occassion Split" },
    { id: 3, title: "Product Split" },
    { id: 4, title: "Promo Performance" },
    { id: 5, title: "B2B - Specific Reports" },
    { id: 6, title: "Unlocked Orders Follow Up" },
  ];
  const tabCard2 = [
    { id: 1, title: "Sales - Invoiced Finacial View" },
    { id: 2, title: "Daily Sales - Detailed" },
  ];

  const options = [
    { label: "Select all", value: "all" },
    { label: "ACCESSORIES", value: "accessories" },
    { label: "CHOCOLATE", value: "chocolate" },
    { label: "PEANUT", value: "peanut" },
  ];

  const handleTabClick = (id: any) => {
    setSelectedTab(id);
  };

  const handleRoundTabClick = (id: any) => {
    setSelectedRoundTab(id);
  };

  return (
    <div className="w-full overflow-x-scroll">
      <div className="flex gap-3 items-center justify-between">
        {tabCard1.map((item) => (
          <button
            key={item.id}
            onClick={() => handleRoundTabClick(item.id)}
            className={`w-full whitespace-nowrap px-6 py-2 rounded-xl text-sm font-medium transition-colors duration-200 ${
              selectedRoundTab === item.id
                ? "bg-blue-400 text-white"
                : "border border-gray-300 text-gray-600 hover:bg-gray-100"
            }`}
          >
            {item.title}
          </button>
        ))}
      </div>

      <div className="flex gap-2 mt-3">
        {tabCard2.map((item) => (
          <button
            key={item.id}
            onClick={() => handleTabClick(item.id)}
            className={`w-full whitespace-nowrap px-8 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
              selectedTab === item.id
                ? "bg-blue-600 text-white"
                : "bg-gray-500 text-white hover:bg-gray-600"
            }`}
          >
            {item.title}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
        <div className="sm:col-span-12 md:col-span-8 lg:col-span-8 space-y-4">
          <div className="bg-blue-50 p-4 mt-3 text-sm text-gray-600 italic text-center">
            <p>
              Information: All orders have been selected that have an invoice
              date recorded
            </p>
          </div>
        </div>
        <div className="sm:col-span-12 md:col-span-4 lg:col-span-4 space-y-4">
          <div className="flex gap-2 mt-3">
            <div className="bg-gray-200 px-2.5 pb-1 w-full">
              <div className="text-xs text-gray-600">
                Search by Discount Code
              </div>
              <Select
                className="bg-white"
                style={{ width: "100%" }}
                bordered={false}
                showSearch
                allowClear
                options={options}
                placeholder="Search"
              />
            </div>
            <div className="bg-gray-200 px-2.5 pb-1 w-full">
              <div className="text-xs text-gray-600">Product Flavour</div>
              <Select
                className="bg-gray-200"
                style={{ width: "100%" }}
                bordered={false}
                options={options}
                placeholder="All"
                allowClear
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemandNavigation;
