import React from "react";
import { Form, Select } from "antd";

const FilterBox = ({
  label,
  defaultValue,
  disabled,
  form,
  name,
  options = [
    {
      label: "All",
      value: "All",
    },
  ],
}: any) => {
  const handleChange = (selectedValues: string[]) => {
    // Check if "All" is selected
    const allValues = options.map((option: any) => option.value);

    if (selectedValues.includes("All")) {
      if (selectedValues.length === options.length) {
        return [];
      }
      return allValues.filter((value: any) => value !== "All");
    }

    return selectedValues.filter((value) => value !== "All");
  };
  return (
    <div className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none">
      <div className="w-full bg-white rounded shadow-sm border">
        <div className="p-0.5">
          <div className="min-w-0 flex-1">
            <div className="text-xs text-gray-500 truncate pl-1 pt-1">
              {label}
            </div>
            <Form.Item
              name={name}
              className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none mb-0"
            >
              <Select
                showSearch
                allowClear
                mode="multiple"
                style={{ width: "100%" }}
                disabled={disabled}
                bordered={false}
                onChange={(value) => {
                  const updatedValues = handleChange(value);
                  // Update the form field dynamically
                  form.setFieldsValue({
                    [name]: updatedValues,
                  });
                }}
                filterOption={(input: any, option: any) =>
                  (option?.label ?? "")
                    ?.toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={options}
              />
            </Form.Item>
          </div>
        </div>
      </div>
    </div>
  );
};

function Filters(props: any) {
  const handleChange = (selectedValues: string[]) => {
    // Check if "All" is selected
    const allValues = props.salesChannel2Options.options.map(
      (option: any) => option.value
    );

    if (selectedValues.includes("All")) {
      if (selectedValues.length === props.salesChannel2Options.options.length) {
        return [];
      }
      return allValues.filter((value: any) => value !== "All");
    }

    return selectedValues.filter((value) => value !== "All");
  };
  return (
    <div className="w-full overflow-x-auto">
      {/* Container with horizontal scrolling on mobile */}
      {/* <div className="max-w-[100vw] "> */}
      {/* Inner container with padding and spacing */}
      <Form
        form={props.form}
        onValuesChange={props.handleFormChange}
        className="w-full overflow-x-auto"
      >
        <div className="min-w-max pb-5">
          {/* Flex container for filters */}
          <div className="flex flex-row gap-4">
            {props.filters?.map((filter: any) => (
              <FilterBox
                key={filter.label}
                name={filter.name}
                label={filter.label}
                disabled={filter?.disabled}
                defaultValue={filter.defaultValue}
                options={filter.options}
                form={props.form}
              />
            ))}
            <Form.Item
              name="salesChannel2"
              className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none mb-0"
            >
              <div className="flex-1 min-w-[150px] max-w-[200px] sm:max-w-none">
                <div className="w-full bg-white rounded shadow-sm border">
                  <div className="p-0.5">
                    <div className="min-w-0 flex-1">
                      <div className="text-xs text-gray-500 truncate pl-1 pt-1">
                        Sales Channel 2
                      </div>
                      <Select
                        showSearch
                        allowClear
                        mode="multiple"
                        style={{ width: "100%" }}
                        disabled={props.isSalesChannel2Disabled}
                        bordered={false}
                        filterOption={(input: any, option: any) =>
                          (option?.label ?? "")
                            ?.toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={props.salesChannel2Options.options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
      {/* </div> */}
    </div>
  );
}

export default Filters;
