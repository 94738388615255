import React, { useState, useEffect, useRef } from "react";
import "./styles.css"; // Import the CSS file for the LoadingBox styles
import MLoGO from "../../assets/mlogo.svg";

const colors: string[] = [
  "#8B4513",
  "#FFA500",
  "#9400D3",
  "#FF0000",
  "#ffd200",
  "#008000",
  "#007aff",
];

const LoadingBox: React.FC = () => {
  const [currentColorIndex, setCurrentColorIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  const currentColor: string = colors[currentColorIndex];

  return (
    <div className="loading-container">
      <div className="sweet" style={{ backgroundColor: currentColor }}>
        <div className="glow" />
        <img src={MLoGO} className="Mlogoss" alt="MLogo" />
        <div className="glow" />
      </div>
    </div>
  );
};

export default LoadingBox;
