import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ArrowLeft,
  CircleDollarSign,
  Package,
  User,
  Info,
  Settings,
  Clock,
  LayoutDashboard,
  ChevronRight,
  ChevronDown,
  Check,
  ListFilter,
  Telescope,
  Box,
  Mail,
  MapPin,
  CreditCard,
  UserCircle,
  Calendar,
  Globe,
  Building,
  Truck,
  Gift,
  ShoppingBag,
  BadgeDollarSign,
  PackageCheck,
  ShoppingCart,
} from "lucide-react";
import Navbar from "../../components/navigation/navbar";
import { GET } from "../../utils/apiCalls";
import API from "../../config/api";
import moment from "moment";

// Collapsible Section Component
const CollapsibleSection = ({ title, icon: Icon, children }: any) => {
  const [isExpanded, setIsExpanded] = useState(true); // Changed to true for initial expanded state

  return (
    <div className="bg-white rounded-lg shadow-md p-4">
      <div
        className="bg-gray-100 flex items-center justify-between mb-1 p-3 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center gap-2">
          <Icon className="h-5 w-5 text-blue-500" />
          <span className="font-bold text-blue-500">
            {title}{" "}
            {isExpanded ? (
              <ChevronDown className="inline h-5 w-5" />
            ) : (
              <ChevronRight className="inline h-5 w-5" />
            )}
          </span>
        </div>
      </div>
      {isExpanded && (
        <div className="text-sm text-gray-600 mt-1">{children}</div>
      )}
    </div>
  );
};

const ProductList = ({ productsArray }: { productsArray: string }) => {
  const products = productsArray?.split("@@") || [];

  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse min-w-full">
        <thead>
          <tr className="bg-gray-100">
            <th className="border p-2 text-left">Product ID</th>
            <th className="border p-2 text-left">Quantity</th>
            <th className="border p-2 text-left">Price</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => {
            const [productId, quantity, price] = product?.split("#");
            return (
              <tr key={index} className="hover:bg-gray-50">
                <td className="border p-2">{productId}</td>
                <td className="border p-2">{quantity}</td>
                <td className="border p-2">{price}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const OrderSummaryContent = ({ orderData }: any) => {
  const SummaryItem = ({
    label,
    value,
    icon: Icon,
    highlight = false,
  }: any) => (
    <div
      className={`flex items-start gap-3 p-2 ${
        highlight ? "bg-blue-50 rounded-lg" : ""
      }`}
    >
      <div className={`${highlight ? "text-blue-600" : "text-gray-400"}`}>
        <Icon className="h-5 w-5" />
      </div>
      <div className="flex-grow min-w-0">
        <div className="text-sm text-gray-500">{label}</div>
        <div
          className={`mt-1 font-medium ${
            highlight ? "text-blue-600" : "text-gray-900"
          } break-words`}
        >
          {value}
        </div>
      </div>
    </div>
  );

  return (
    <div className="divide-y divide-gray-100">
      <SummaryItem
        label="Order Number"
        value={orderData?.ORDERNUMBER}
        icon={Package}
        highlight={true}
      />
      <SummaryItem
        label="Created Date"
        value={formatDateTime(orderData?.CREATEDDATE)}
        icon={Calendar}
      />
      <SummaryItem
        label="Channel"
        value={`${orderData?.CHANNEL} (${orderData?.SALESCHANNEL})`}
        icon={ShoppingBag}
      />
      <SummaryItem label="Type" value={orderData?.ORDERTYPE} icon={Building} />
      <SummaryItem
        label="Billing Country"
        value={orderData?.COUNTRY_BILLING}
        icon={Globe}
      />
      <SummaryItem
        label="Shipping Country"
        value={orderData?.COUNTRY_SHIPPING}
        icon={Truck}
      />
      <SummaryItem
        label="Discount Code"
        value={orderData?.DISCOUNTCODE}
        icon={BadgeDollarSign}
      />
      <SummaryItem label="Occasion" value={orderData?.OCCASION} icon={Gift} />
    </div>
  );
};

const CustomerInfoContent = ({ orderData }: any) => {
  const AddressBlock = ({ title, address, icon: Icon }: any) => (
    <div className="bg-gray-50 rounded-lg p-4">
      <div className="flex items-center gap-2 mb-2">
        <Icon className="h-4 w-4 text-blue-600 flex-shrink-0" />
        <span className="font-medium text-gray-700">{title}</span>
      </div>
      <div className="text-gray-600 ml-6">
        {address?.split("@@").map((line: string, index: number) => (
          <div key={index} className="mt-0.5 break-words">
            {line}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-4">
      <div className="bg-white rounded-lg p-4 border border-gray-100 shadow-sm">
        {/* Customer Basic Info Card - Restructured for better name handling */}
        <div className="sm:ml-auto flex-shrink-0">
          <span
            className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${
              orderData?.NEW_RETURNING === "0"
                ? "bg-green-100 text-green-800"
                : "bg-purple-100 text-purple-800"
            }`}
          >
            {orderData?.NEW_RETURNING === "0"
              ? "New Customer"
              : "Returning Customer"}
          </span>
        </div>
        <br />
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex items-start gap-3 min-w-0">
            {" "}
            {/* min-w-0 allows text truncation */}
            <div className="bg-blue-100 rounded-full p-3 flex-shrink-0">
              <UserCircle className="h-6 w-6 text-blue-600" />
            </div>
            <div className="min-w-0">
              {" "}
              {/* Enable text truncation */}
              <h3 className="font-medium text-lg text-gray-900 break-words">
                {orderData?.CUSTOMERNAME}
              </h3>
              <div className="flex items-center gap-2 text-gray-500 mt-1 flex-wrap">
                <Mail className="h-4 w-4 flex-shrink-0" />
                <a
                  href={`mailto:${orderData?.CUSTOMEREMAIL}`}
                  className="text-blue-600 hover:text-blue-700 break-all"
                >
                  {orderData?.CUSTOMEREMAIL}
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Address Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
          <AddressBlock
            title="Shipping Address"
            address={orderData?.CUSTOMERSHIPPINGADDRESS}
            icon={MapPin}
          />
          <AddressBlock
            title="Billing Address"
            address={orderData?.CUSTOMERBILLINGADDRESS}
            icon={CreditCard}
          />
        </div>
      </div>
    </div>
  );
};
const DetailRow = ({ icon: Icon, label, value }: any) => (
  <div className="flex items-start gap-3 p-1 border-b border-gray-100 last:border-b-0">
    <div className="text-gray-400">
      <Icon className="h-5 w-5" />
    </div>
    <div className="flex-grow">
      <div className="text-gray-500 text-sm">{label}</div>
      <div className="text-gray-900 font-medium  mt-0.5 break-words">
        {value || "-"}
      </div>
    </div>
  </div>
);
const formatDateTime = (dateString: any) => {
  try {
    if (!dateString) {
      return "Not Yet";
    }
    return moment(dateString).format("DD-MMM-YYYY HH:mm");
  } catch (e) {
    return dateString || "-";
  }
};
const InformationContent = ({ orderData, activeTab }: any) => {
  const formatCurrency = (amount: any) => {
    return `€${parseFloat(amount || 0).toFixed(2)}`;
  };

  return (
    <div className="bg-white rounded-lg">
      {activeTab === "details" ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <DetailRow
              label="Order State"
              value={orderData?.ORDERSTATE}
              icon={PackageCheck}
            />
            <DetailRow
              label="Payment Status"
              value={orderData?.PAYMENTSTATE}
              icon={CreditCard}
            />
            <DetailRow
              icon={ShoppingBag}
              label="Currency"
              value={orderData?.CURRENCY}
            />
          </div>
          <div className="space-y-4">
            <DetailRow
              icon={BadgeDollarSign}
              label="Total Amount"
              value={`${formatCurrency(
                orderData?.TOTAL_TAXINC
              )} (Tax: ${formatCurrency(orderData?.TAXAMOUNT)})`}
            />
            <DetailRow
              icon={Truck}
              label="Shipping Cost"
              value={formatCurrency(orderData?.SHIPAMOUNT_TAXFREE)}
            />
            <DetailRow
              icon={BadgeDollarSign}
              label="Fees"
              value={formatCurrency(orderData?.FEES)}
            />
          </div>
        </div>
      ) : activeTab === "product" ? (
        <ProductList productsArray={orderData?.ORDEREDPRODUCTS} />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <DetailRow
              icon={Truck}
              label="Carrier"
              value={orderData?.CARRIERNAME}
            />

            <DetailRow
              icon={ShoppingBag}
              label="Acknowledged Delivery Receipt"
              value={orderData?.ACKNOWLEDGEDDELIVERYRECEIPT}
            />
            <DetailRow
              icon={Calendar}
              label="Estimated Arrival Date"
              value={formatDateTime(orderData?.ESTIMATEDARRIVALDATE)}
            />
            <DetailRow
              icon={Calendar}
              label="Estimated Shipping Date"
              value={formatDateTime(orderData?.ESTIMATEDSHIPPINGDATE)}
            />
          </div>
          <div className="space-y-4">
            <DetailRow
              icon={Telescope}
              label="Tracking"
              value={orderData?.CARRIERTRACKINGNUMBER}
            />
            <DetailRow
              icon={Calendar}
              label="Shipped Package"
              value={orderData?.SHIPPEDPACKAGES}
            />
            <DetailRow
              icon={Calendar}
              label="Shipment State"
              value={orderData?.SHIPMENTSTATE}
            />
            <DetailRow
              icon={Box}
              label="Carrier Delivery Mode"
              value={orderData?.CARRIERDELIVERYMODE}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const OrderView = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [activeTab, setActiveTab] = useState("product");
  const getOrderSteps = (orderState: any) => {
    const baseSteps = [
      { label: "Lead", status: "pending" },
      { label: "New", status: "pending" },
      { label: "Global IMS", status: "pending" },
      { label: "Planning", status: "pending" },
      { label: "Production", status: "pending" },
      { label: "Shipped", status: "pending" },
    ];

    switch (orderState) {
      case "Lead":
        return baseSteps.map((step, index) => ({
          ...step,
          status: index < 0 ? "completed" : index === 0 ? "current" : "pending",
        }));

      case "Created":
        return baseSteps.map((step, index) => ({
          ...step,
          status: index < 1 ? "completed" : index === 1 ? "current" : "pending",
        }));

      case "Approved":
        return baseSteps.map((step, index) => ({
          ...step,
          status: index < 2 ? "completed" : index === 2 ? "current" : "pending",
        }));

      case "Refunded": {
        const modifiedSteps = [
          ...baseSteps.slice(0, 3),
          { label: "Refunded", status: "current" },
          ...baseSteps.slice(3),
        ];
        return modifiedSteps.map((step, index) => ({
          ...step,
          status: index < 3 ? "completed" : index === 3 ? "current" : "pending",
        }));
      }

      case "Cancelled": {
        const modifiedSteps = [
          ...baseSteps.slice(0, 3),
          { label: "Cancelled", status: "current" },
          ...baseSteps.slice(3),
        ];
        return modifiedSteps.map((step, index) => ({
          ...step,
          status: index < 3 ? "completed" : index === 3 ? "current" : "pending",
        }));
      }

      case "Pending refund":
        return baseSteps.map((step, index) => ({
          ...step,
          status: index < 3 ? "completed" : index === 3 ? "current" : "pending",
        }));

      case "Planning":
        return baseSteps.map((step, index) => ({
          ...step,
          status: index < 3 ? "completed" : index === 3 ? "current" : "pending",
        }));

      case "Shipment in progress":
        return baseSteps.map((step, index) => ({
          ...step,
          status: index < 4 ? "completed" : index === 4 ? "current" : "pending",
        }));

      case "Delivered":
        return baseSteps.map((step, index) => ({
          ...step,
          status: index < 5 ? "completed" : index === 5 ? "current" : "pending",
        }));

      default:
        return baseSteps;
    }
  };

  useEffect(() => {
    // Fetch order details
    const fetchOrderDetails = async () => {
      try {
        setLoading(true);
        const response: any = await GET(API.EU_ALLORDER + orderId);
        setOrderData(response);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch order details. Please try again.");
        setLoading(false);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />

      {/* Header */}
      <div className="flex items-center justify-between flex-wrap border-b border-gray-200 px-6 py-4 bg-white">
        <div className="flex items-center gap-3">
          <ArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => navigate("/eu-orders")}
          />
          <div className="flex items-center gap-2">
            <LayoutDashboard />
            <h1 className="text-xl font-medium">
              Orders: <span className="font-bold">{orderId}</span>
            </h1>
          </div>
        </div>
        <div className="flex gap-2">
          <button className="px-4 py-1.5 text-gray-700 border border-gray-300 rounded hover:bg-gray-50">
            Edit
          </button>
          <button className="px-4 py-1.5 text-white bg-blue-600 rounded hover:bg-blue-700">
            Save
          </button>
        </div>
      </div>

      <div className="p-6">
        {/* Analytics Cards */}
        {/* <div className="grid grid-cols-4 gap-6 mb-6"> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-6">
          {/* NSV Card */}
          <div className="h-52 bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center gap-2 transform transition-transform duration-300 ease-in-out hover:scale-105">
            <div className="text-4xl font-medium text-blue-600">
              {orderData?.NSV}
            </div>
            <div className="text-sm text-gray-600">NSV (Net Sales Value)</div>
            <CircleDollarSign className="h-6 w-6 text-blue-600 mt-2" />
          </div>

          {/* MAC Card */}
          <div className="h-52 bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center gap-2 transform transition-transform duration-300 ease-in-out hover:scale-105">
            <div className="text-4xl font-medium text-blue-600">
              {orderData?.ORDEREDAMOUNT}
            </div>
            <div className="text-sm text-gray-600">ORDERED AMOUNT</div>
            <Settings className="h-6 w-6 text-blue-600 mt-2" />
          </div>

          {/* Products Card */}
          <div className="h-52 bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center gap-2 transform transition-transform duration-300 ease-in-out hover:scale-105">
            <div className="text-4xl font-medium text-blue-600">
              {orderData?.ORDEREDPRODUCTS?.split("@@")?.length}
            </div>
            <div className="text-sm text-gray-600">Products Ordered</div>
            <Package className="h-6 w-6 text-blue-600 mt-2" />
          </div>

          {/* Customer Card */}
          <div className="h-52 bg-white rounded-lg shadow-md p-6 flex flex-col items-center justify-center gap-2 transform transition-transform duration-300 ease-in-out hover:scale-105">
            <div className="text-4xl font-medium text-blue-600">
              {" "}
              {orderData?.NEW_RETURNING === "0" ? "NO" : "YES"}
            </div>
            <div className="text-sm text-gray-600">Returning Customer</div>
            <User className="h-6 w-6 text-blue-600 mt-2" />
          </div>
        </div>

        {/* Information Sections */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          {/* Order Summary */}
          <div className="col-span-12 sm:col-span-12 md:col-span-3">
            <CollapsibleSection title="Order Summary" icon={ListFilter}>
              <OrderSummaryContent orderData={orderData} />
            </CollapsibleSection>
            <br />
          </div>

          {/* Status Section - Middle */}
          <div className="col-span-12 md:col-span-6 hidden md:block ">
            <div className="bg-white rounded-lg shadow-md p-4">
              <div className="bg-gray-100 flex items-center justify-between flex-wrap mb-1 p-3">
                <div className="flex items-center gap-2">
                  <ListFilter className="h-5 w-5 text-blue-500" />
                  <span className="font-bold text-blue-500">Status</span>
                </div>
                <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                  Change the Status
                </button>
              </div>
              <div className="flex items-center justify-between relative mt-4">
                <div className="flex space-x-[-7px] h-[30px] w-[100%] mt-2 mb-2 ">
                  {getOrderSteps(orderData?.ORDERSTATE).map(
                    (step: any, index: any) => (
                      <div
                        key={index}
                        className={`
                     relative flex items-center w-[20%] md:w-[16%] h-8
                     ${
                       index !== getOrderSteps(orderData?.ORDERSTATE).length - 1
                         ? "flex-1"
                         : ""
                     }
                     ${
                       step.status === "completed"
                         ? "bg-green-500"
                         : step.status === "current"
                         ? "bg-blue-800 hover:bg-blue-900"
                         : "bg-gray-200 hover:bg-gray-300"
                     }
                     ${index === 0 ? "rounded-l-full" : ""}
                     ${
                       index === getOrderSteps(orderData?.ORDERSTATE).length - 1
                         ? "rounded-r-full"
                         : ""
                     }
                   `}
                        style={{
                          clipPath:
                            index === 0
                              ? "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%)"
                              : index ===
                                getOrderSteps(orderData?.ORDERSTATE).length - 1
                              ? "polygon(0 0, 100% 0, 100% 100%, 0 100%, 10px 50%)"
                              : "polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%, 10px 50%)",
                          marginBottom: "4px",
                        }}
                      >
                        <div className="absolute inset-0 flex items-center justify-center">
                          {step.status === "completed" ? (
                            <div className="relative w-full h-full flex items-center justify-center group">
                              <Check className="h-5 w-5 text-white absolute transition-opacity duration-300 group-hover:opacity-0" />
                              <span className="text-sm text-white opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                {step.label}
                              </span>
                            </div>
                          ) : (
                            <span
                              className={`
                  text-sm
                  ${step.status === "pending" ? "text-gray-600" : "text-white"}
                `}
                            >
                              {step.label}
                            </span>
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <br />
            {/* Information Section */}
            <div className="bg-white rounded-lg shadow-md p-4">
              <div className="bg-gray-100 flex items-center gap-2 mb-4 p-3">
                <Info className="h-5 w-5 text-blue-500" />
                <span className="text-lg font-bold text-blue-500">
                  Information
                </span>
              </div>

              {/* Tabs */}
              <div className="flex border-b border-gray-200 mb-4">
                <button
                  className={`flex items-center gap-2 px-4 py-2 font-medium ${
                    activeTab === "product"
                      ? "text-blue-600 border-b-2 border-blue-600"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={() => setActiveTab("product")}
                >
                  <ShoppingCart className="h-4 w-4" />
                  Ordered Products
                </button>
                <button
                  className={`flex items-center gap-2 px-4 py-2 font-medium ${
                    activeTab === "details"
                      ? "text-blue-600 border-b-2 border-blue-600"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={() => setActiveTab("details")}
                >
                  <ListFilter className="h-4 w-4" />
                  Details
                </button>
                <button
                  className={`flex items-center gap-2 px-4 py-2 font-medium ${
                    activeTab === "shipment"
                      ? "text-blue-600 border-b-2 border-blue-600"
                      : "text-gray-500 hover:text-gray-700"
                  }`}
                  onClick={() => setActiveTab("shipment")}
                >
                  <Truck className="h-4 w-4" />
                  Shipment
                </button>
              </div>
              <InformationContent orderData={orderData} activeTab={activeTab} />
            </div>
          </div>

          {/* Mobile View */}
          <div className="block md:hidden col-span-12">
            <div className="bg-white rounded-lg shadow-md p-4">
              <div className="bg-gray-100 flex items-center justify-between flex-wrap mb-1 p-3">
                <div className="flex items-center gap-2">
                  <ListFilter className="h-5 w-5 text-blue-500" />
                  <span className="font-bold text-blue-500">Status</span>
                </div>
                <button className="px-3 py-1 text-sm bg-blue-600 text-white rounded-md hover:bg-blue-700">
                  Change
                </button>
              </div>
              <div className="grid grid-cols-2 gap-2 mt-4">
                {/* Mobile Status Options */}
                {[
                  "New",
                  "Global IMS",
                  "Planning",
                  "Production",
                  "Shipped",
                  "Delivered",
                ].map((status, index) => (
                  <div
                    key={status}
                    className={`h-8 ${
                      index === 0
                        ? "rounded-l-full"
                        : index === 5
                        ? "rounded-r-full"
                        : ""
                    } ${
                      index < 3
                        ? "bg-green-600 text-white"
                        : index === 3
                        ? "bg-[#014486] hover:opacity-80"
                        : "bg-gray-200 hover:bg-gray-300 text-black"
                    } 
          shadow-md px-2 py-1 text-sm flex items-center justify-center relative group transition-colors duration-300`}
                  >
                    {/* Check Icon */}
                    {index < 3 && (
                      <Check
                        className={`h-5 w-5 absolute group-hover:opacity-0 transition-opacity duration-300 opacity-100`}
                      />
                    )}
                    {/* Label Name */}
                    <span
                      className={`absolute group-hover:opacity-100 transition-opacity duration-300 ${
                        index < 3 ? "opacity-0" : "opacity-100"
                      }`}
                    >
                      {status}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Timeline */}
          <div className="col-span-12 sm:col-span-12 md:col-span-3">
            <CollapsibleSection title="Customer Info" icon={User}>
              <CustomerInfoContent orderData={orderData} />
            </CollapsibleSection>
            <br />
            {/* Activity Feed */}
            <CollapsibleSection title="Activity Feed" icon={ListFilter}>
              <div className="space-y-4">
                <DetailRow
                  icon={Calendar}
                  label="Created"
                  value={formatDateTime(orderData?.CREATEDDATE)}
                />
                <DetailRow
                  icon={Clock}
                  label="Invoiced"
                  value={formatDateTime(orderData?.INVOICEDATE)}
                />
                <DetailRow
                  icon={ShoppingBag}
                  label="Shipped"
                  value={formatDateTime(orderData?.SHIPPEDDATE)}
                />
                <DetailRow
                  icon={Calendar}
                  label="Last Updated"
                  value={formatDateTime(orderData?.DATE_LASTUPDATE)}
                />
              </div>
            </CollapsibleSection>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderView;
